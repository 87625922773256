import { useMemo, useState, type FC } from "react";
import { LoadingState } from "@/components/podkit/loading/LoadingState";
import { useAuthenticatedUser } from "@/queries/user-queries";
import { useListPersonalAccessTokens, type PlainPersonalAccessToken } from "@/queries/personal-access-tokens-queries";
import { NewPersonalAccessTokenModal } from "@/routes/settings/user/personal-access-tokens/NewPersonalAccessTokenModal";
import { useMembers, type PlainOrganizationMember } from "@/queries/organization-queries";
import { Button } from "@/components/flexkit/Button";
import { DeletePersonalAccessTokenModal } from "@/routes/settings/user/personal-access-tokens/DeletePersonalAccessTokenModal";
import { Text } from "@/components/podkit/typography/Text";
import { Heading2 } from "@/components/podkit/typography/Headings";
import { getCreationTime, getValidityDuration } from "@/routes/settings/user/personal-access-tokens/time-format";

export const PersonalAccessTokensList: FC = () => {
    const { data: user, isLoading: isLoadingUser } = useAuthenticatedUser();
    const useListPersonalAccessTokensFilter = { userId: user?.id || "" };
    const {
        data: patsData,
        isLoading: isLoadingPATs,
        isPending: isPendingPATs,
    } = useListPersonalAccessTokens(useListPersonalAccessTokensFilter);

    const { data: membersData, isLoading: isLoadingMembers, isPending: isPendingMembers } = useMembers();

    const isLoading = isLoadingPATs || isLoadingMembers || isPendingPATs || isPendingMembers || isLoadingUser;

    const patsToShow = useMemo(() => {
        if (!membersData || !patsData || !user) {
            return [];
        }

        const currentMember = membersData.members.find((m) => m.userId === user.id);

        return patsData.pats.map((pat) => {
            return {
                pat,
                creator: membersData.members.find((m) => m.userId === pat.creator?.id),
                currentMember,
            };
        });
    }, [membersData, patsData, user]);

    const [showNewPATModal, setShowNewPATModal] = useState(false);

    if (isLoading) {
        return <LoadingState />;
    }

    const hasPATs = patsToShow.length > 0;

    return (
        <div data-testid="runners-list">
            <p className="mb-6">
                <div className="flex flex-row gap-4">
                    <Text className="text-base text-content-secondary">
                        Personal access tokens (PATs) are secure credentials that allow API access to Gitpod without
                        manually logging in. They enable integration with external tools and workflow automation, while
                        providing users the ability to revoke access when needed.
                    </Text>
                    {hasPATs && (
                        <Button
                            className="h-full"
                            onClick={() => setShowNewPATModal(true)}
                            size={"md"}
                            variant={"secondary"}
                            data-track-label="true"
                        >
                            New Token
                        </Button>
                    )}
                </div>
            </p>
            <div className="flex min-h-screen flex-col flex-wrap items-center gap-2">
                {patsToShow?.map(({ pat, creator, currentMember }) => (
                    <PersonalAccessTokenRow key={pat.id} pat={pat} creator={creator} currentMember={currentMember} />
                ))}
                {!hasPATs && (
                    <div className="flex flex-col gap-4 py-10 text-center">
                        <div className="flex flex-col">
                            <Heading2 className="text-lg font-bold">No Personal Access Tokens</Heading2>
                            <div className="text-base">Create a new token to integrate with other systems.</div>
                        </div>
                        <Button
                            onClick={() => setShowNewPATModal(true)}
                            size={"md"}
                            variant={"primary"}
                            data-track-label="true"
                        >
                            New Token
                        </Button>
                    </div>
                )}
                {showNewPATModal && <NewPersonalAccessTokenModal onClose={() => setShowNewPATModal(false)} />}
            </div>
        </div>
    );
};

const PersonalAccessTokenRow: FC<{
    pat: PlainPersonalAccessToken;
    creator?: PlainOrganizationMember;
    currentMember?: PlainOrganizationMember;
}> = ({ pat, creator, currentMember }) => {
    const [showDeletionModal, setShowDeletionModal] = useState(false);

    return (
        <>
            <div className="group inline-flex h-14 w-full items-center justify-start gap-4 rounded-xl border border-border-base bg-surface-glass px-4 py-2.5 text-xs hover:shadow">
                <div className="min-w-6/12 max-w-6/12 flex w-6/12 flex-col truncate">
                    <Text className="truncate text-base font-bold text-content-primary">{pat.description}</Text>
                    <Text className="truncate text-sm text-content-secondary">
                        by{" "}
                        {currentMember?.userId === creator?.userId ? (
                            <span>You</span>
                        ) : (
                            creator?.fullName || <span>Unknown</span>
                        )}
                        {" · "}
                        {getCreationTime(pat)}
                    </Text>
                </div>
                <div className="max-w-3/12 flex w-3/12 flex-col items-start justify-start truncate">
                    <div className="truncate text-base text-content-secondary">{getValidityDuration(pat)}</div>
                </div>
                <div className="max-w-4/12 flex w-3/12 items-end justify-end gap-2">
                    <Button
                        variant={"primary"}
                        size={"md"}
                        onClick={() => setShowDeletionModal(true)}
                        data-track-label="true"
                    >
                        Remove
                    </Button>
                </div>
            </div>
            {showDeletionModal && (
                <DeletePersonalAccessTokenModal pat={pat} onClose={() => setShowDeletionModal(false)} />
            )}
        </>
    );
};
