import { Text } from "@/components/podkit/typography/Text";
import { TimelineDot } from "@/routes/onboarding/components/TimelineDot";
import type { RunnerConfigurationPhase } from "@/routes/onboarding/use-runner-configuration-progress";
import { type FC } from "react";

export const TimelinePill: FC<{ phase: RunnerConfigurationPhase }> = ({ phase }) => {
    return (
        <div className="flex items-center justify-center gap-1 rounded-[100px] border-0.5 border-border-base bg-surface-01 p-1 pr-3 text-center shadow-sm">
            <TimelineDot state={phase.state} />
            <Text className="text-sm font-medium text-content-primary">{phase.label}</Text>
        </div>
    );
};
