import { lazy, Suspense, type FC } from "react";
import { SkeletonBlock } from "@/components/podkit/loading/Skeleton";

const MuxPlayer = lazy(() => import("@mux/mux-player-react"));

type VideoSectionProps = {
    playbackId: string;
    metadataVideoTitle: string;
    poster?: string;
};

export const VideoSection: FC<VideoSectionProps> = ({ playbackId, metadataVideoTitle, poster }) => (
    <div className="flex w-full flex-col items-start justify-start overflow-hidden rounded-md drop-shadow-xl">
        <Suspense fallback={<SkeletonBlock ready={false} className="h-[300px] w-full" />}>
            <MuxPlayer
                data-track-label={"Video: " + metadataVideoTitle}
                streamType="on-demand"
                playbackId={playbackId}
                metadataVideoTitle={metadataVideoTitle}
                primaryColor="#FFFFFF"
                secondaryColor="#000000"
                accentColor="#FF8A00"
                defaultHiddenCaptions={true}
                poster={poster}
                style={{
                    aspectRatio: 16 / 9,
                    backgroundColor: "var(--surface-tertiary)",
                    borderRadius: "6px",
                }}
            />
        </Suspense>
    </div>
);
