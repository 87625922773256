import { useListRunnerSCMIntegrations } from "@/queries/runner-configuration-queries";
import { useRunner } from "@/queries/runner-queries";
import { RunnerPhase } from "gitpod-next-api/gitpod/v1/runner_pb";

export type RunnerConfigurationProgress =
    | { id: "install-runner"; runnerId?: string }
    | { id: "configure-scm"; runnerId: string }
    | { id: "configure-environment-classes"; runnerId: string }

type RunnerConfigurationProgressIds = RunnerConfigurationProgress["id"];

export type RunnerConfigurationPhase = {
    id: RunnerConfigurationProgressIds;
    label: string;
    state: "active" | "done" | "todo";
};

export type RunnerConfigurationPhaseState = RunnerConfigurationPhase["state"];

export function useRunnerConfigurationProgress(runnerId?: string): {
    isLoading: boolean;
    progress?: RunnerConfigurationProgress;
} {
    const { data: runner, isLoading: isLoadingRunner } = useRunner(runnerId);
    const { data: integrations, isLoading: isLoadingIntegrations } = useListRunnerSCMIntegrations(runner?.runnerId);

    if (runnerId && isLoadingRunner) {
        return {
            isLoading: true,
        };
    }

    if (!runner) {
        return {
            isLoading: false,
            progress: { id: "install-runner" },
        };
    }

    if (isLoadingIntegrations) {
        return {
            isLoading: true,
        };
    }

    let progress: RunnerConfigurationProgress;
    if (runner?.status?.phase === RunnerPhase.CREATED) {
        progress = { id: "install-runner", runnerId: runner.runnerId };
    } else if (!integrations?.length) {
        progress = { id: "configure-scm", runnerId: runner.runnerId };
    } else {
        progress = { id: "configure-environment-classes", runnerId: runner.runnerId };
    }

    return {
        isLoading: false,
        progress,
    };
}

const phases: Omit<RunnerConfigurationPhase, "state">[] = [
    {
        id: "install-runner",
        label: "Step 1: Install runner",
    },
    {
        id: "configure-scm",
        label: "Configure Git provider",
    },
    {
        id: "configure-environment-classes",
        label: "Select environment Classes",
    }
];

export function phasesFromProgress(progress?: RunnerConfigurationProgress): RunnerConfigurationPhase[] {
    const activeIndex = phases.findIndex((phase) => phase.id === progress?.id);

    const result: RunnerConfigurationPhase[] = [];
    phases.forEach((phase, index) => {
        const state = index < activeIndex ? "done" : index === activeIndex ? "active" : "todo";
        result.push({
            ...phase,
            state,
        });
    });

    return result;
}
