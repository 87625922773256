import type { Size } from "@/assets/icons/geist/Size";
import { type PropsWithClassName } from "@/components/podkit/lib/cn";
import type { FC } from "react";

export const IconZap: FC<{ size: Size } & PropsWithClassName> = ({ size, className }) => {
    switch (size) {
        case "sm":
            return (
                <svg
                    className={className}
                    width="16"
                    height="16"
                    viewBox="0 0 16 16"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                >
                    <path
                        d="M16.4982 7.29169H11.4584C11.2283 7.29169 11.0417 7.10514 11.0417 6.87502V1.96311C11.0417 1.55423 10.5141 1.38996 10.282 1.72655L3.15885 12.0551C2.96823 12.3315 3.16608 12.7084 3.50185 12.7084H8.54169C8.77177 12.7084 8.95835 12.8949 8.95835 13.125V18.0369C8.95835 18.4459 9.48585 18.6101 9.71802 18.2735L16.8412 7.94491C17.0319 7.66851 16.8339 7.29169 16.4982 7.29169Z"
                        stroke="currentColor"
                        strokeWidth="1.25"
                        strokeLinejoin="round"
                    />
                </svg>
            );
        case "base":
            return (
                <svg
                    className={className}
                    width="20"
                    height="20"
                    viewBox="0 0 20 20"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                >
                    <path
                        d="M16.4982 7.29169H11.4584C11.2283 7.29169 11.0417 7.10514 11.0417 6.87502V1.96311C11.0417 1.55423 10.5141 1.38996 10.282 1.72655L3.15885 12.0551C2.96823 12.3315 3.16608 12.7084 3.50185 12.7084H8.54169C8.77177 12.7084 8.95835 12.8949 8.95835 13.125V18.0369C8.95835 18.4459 9.48585 18.6101 9.71802 18.2735L16.8412 7.94491C17.0319 7.66851 16.8339 7.29169 16.4982 7.29169Z"
                        stroke="currentColor"
                        strokeWidth="1.25"
                        strokeLinejoin="round"
                    />
                </svg>
            );
        case "lg":
            return (
                <svg
                    className={className}
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                >
                    <path
                        d="M16.4982 7.29169H11.4584C11.2283 7.29169 11.0417 7.10514 11.0417 6.87502V1.96311C11.0417 1.55423 10.5141 1.38996 10.282 1.72655L3.15885 12.0551C2.96823 12.3315 3.16608 12.7084 3.50185 12.7084H8.54169C8.77177 12.7084 8.95835 12.8949 8.95835 13.125V18.0369C8.95835 18.4459 9.48585 18.6101 9.71802 18.2735L16.8412 7.94491C17.0319 7.66851 16.8339 7.29169 16.4982 7.29169Z"
                        stroke="currentColor"
                        strokeWidth="1.25"
                        strokeLinejoin="round"
                    />
                </svg>
            );
    }
};
