import { useDocumentTitle } from "@/hooks/use-document-title";
import { Heading4 } from "@/components/podkit/typography/Headings";
import { Text } from "@/components/podkit/typography/Text";
import type React from "react";
import { useCallback, useEffect, useMemo, useState, type FC } from "react";
import {
    phasesFromProgress,
    useRunnerConfigurationProgress,
    type RunnerConfigurationProgress,
} from "@/routes/onboarding/use-runner-configuration-progress";
import { Timeline } from "@/routes/onboarding/components/Timeline";
import { TimelineContent } from "@/routes/onboarding/components/TimelineContent";
import { SkeletonBlock } from "@/components/podkit/loading/Skeleton";
import { useAuthenticatedUser } from "@/queries/user-queries";
import { useListRunners } from "@/queries/runner-queries";
import { RunnerKind } from "gitpod-next-api/gitpod/v1/runner_pb";
import { OnboardingSetupRunnerStepId } from "@/hooks/use-onboarding";
import { OnboardingTabContent } from "@/routes/onboarding/components/OnboardingTabContent";
import appleSilicon from "@/assets/apple-silicon.svg";
import awsLogo from "@/assets/aws.svg";
import azureLogo from "@/assets/azure.svg";
import gcpLogo from "@/assets/gcp.svg";
import linuxLogo from "@/assets/linux.svg";
import { VideoSection } from "@/routes/onboarding/components/VideoSection";
import { cn } from "@/components/podkit/lib/cn";
import { Button } from "@/components/flexkit/Button";
import { TrackLocations, useSegmentTrack } from "@/hooks/use-segment";
import { DESKTOP_APP_DOWNLOAD_URL } from "@/desktop";
import { IconExternalLink } from "@/assets/icons/geist/IconExternalLink";
import { CheckedText } from "@/components/CheckedText";
import setupRunnerImg from "@/assets/runner-setup-thumbnail.jpg";

export const SetupARunnerPage: FC<{ onNext: () => void; onBack: () => void }> = ({ onNext }) => {
    useDocumentTitle("Onboarding - Setup a runner");

    const [setupRunnerStep, setSetupRunnerStep] = useState(OnboardingSetupRunnerStepId.ChoseRunner);

    const { data: user } = useAuthenticatedUser();
    const { data: runners, isLoading: isLoadingRunners } = useListRunners({
        kind: RunnerKind.REMOTE,
        creatorId: user?.id,
    });

    const { progress: initialProgress, isLoading } = useRunnerConfigurationProgress(runners?.runners[0]?.runnerId);
    const [progress, setProgress] = useState<RunnerConfigurationProgress | undefined>();

    useEffect(() => {
        if (progress || !initialProgress || isLoadingRunners) {
            return;
        }
        if (initialProgress.runnerId) {
            setSetupRunnerStep(OnboardingSetupRunnerStepId.ConfigureInfrastructure);
        }
        setProgress(initialProgress);
    }, [progress, initialProgress, isLoadingRunners, setProgress, setSetupRunnerStep]);

    if (isLoading || !progress) {
        return (
            <OnboardingTabContent showDivider={false} data-track-location={TrackLocations.OnboardingRunnerSelectTab}>
                <Loading />
            </OnboardingTabContent>
        );
    }

    return (
        <>
            {setupRunnerStep === OnboardingSetupRunnerStepId.ChoseRunner && (
                <SelectARunnerPage
                    onContinue={() => setSetupRunnerStep(OnboardingSetupRunnerStepId.SelectProvider)}
                    onContinueMac={() => onNext()}
                />
            )}
            {setupRunnerStep === OnboardingSetupRunnerStepId.SelectProvider && (
                <SelectAProviderPage
                    onSelectProvider={() => setSetupRunnerStep(OnboardingSetupRunnerStepId.ConfigureInfrastructure)}
                    onContinueWithDesktop={onNext}
                />
            )}
            {setupRunnerStep === OnboardingSetupRunnerStepId.ConfigureInfrastructure && (
                <ConfigureYourInfrastructurePage
                    progress={progress}
                    setProgress={setProgress}
                    onBack={() => setSetupRunnerStep(OnboardingSetupRunnerStepId.ChoseRunner)}
                    onNext={onNext}
                />
            )}
        </>
    );
};

const SelectARunnerPage: FC<{ onContinue: () => void; onContinueMac: () => void }> = ({
    onContinue,
    onContinueMac,
}) => {
    const usingGitpodDesktop = window.ipcRenderer;
    return (
        <OnboardingTabContent
            data-track-location={TrackLocations.OnboardingRunnerSelectTab}
            title="Set up a runner"
            description="The infrastructure that runs your dev environments. Self-host in your cloud in under 3 minutes, or run locally on your Mac."
            showDivider={false}
            learnMoreLink="https://www.gitpod.io/docs/flex/runners/aws/setup-aws-runners"
        >
            <div className="flex flex-col gap-6 lg:flex-row">
                <BenefitsCard
                    heading="Gitpod Desktop"
                    subheading="No cloud access? Start local."
                    icon={<img src={appleSilicon} className="size-12" />}
                    benefits={[
                        { text: "Run Dev Container locally with built-in Linux virtualization", checked: true },
                        { text: "No infrastructure costs—everything runs locally", checked: true },
                        { text: "Project sharing only possible with other Gitpod Desktop users", checked: false },
                    ]}
                    button={
                        usingGitpodDesktop ? (
                            <Button
                                size="lg"
                                variant="primary"
                                className="w-full"
                                onClick={onContinueMac}
                                data-track-label={"Continue with Gitpod Desktop"}
                            >
                                Continue
                            </Button>
                        ) : (
                            <Button size="lg" variant="primary" className="w-full" asChild data-track-label="true">
                                <a href={DESKTOP_APP_DOWNLOAD_URL} download onClick={onContinueMac}>
                                    Download Mac App (Apple Silicon)
                                </a>
                            </Button>
                        )
                    }
                    learnMoreLink="https://www.gitpod.io/docs/flex/gitpod-desktop"
                />
                <BenefitsCard
                    heading="Self-host Runner"
                    subheading="3 minute installation, no AWS expertise required."
                    icon={<IconStack />}
                    benefits={[
                        { text: "Leverage your own infrastructure", checked: true },
                        { text: "Can be shared and used by everyone in your org", checked: true },
                        {
                            text: "Environment classes up to 896 vCPUs and 12TB RAM, GPU support",
                            checked: true,
                        },
                        { text: "Fixed costs covered by the AWS free tier", checked: true },
                    ]}
                    button={
                        <Button
                            size="lg"
                            variant="primary"
                            className="w-full bg-content-orange"
                            onClick={onContinue}
                            data-track-label={"Continue with Self-host Runner"}
                        >
                            Continue
                        </Button>
                    }
                    learnMoreLink="https://www.gitpod.io/docs/flex/runners"
                />
            </div>
        </OnboardingTabContent>
    );
};

type Benefit = {
    text: string;
    checked: boolean;
};

const BenefitsCard: FC<{
    heading: string;
    subheading: string;
    icon: React.ReactNode;
    benefits: Benefit[];
    button: React.ReactNode;
    learnMoreLink: string;
}> = ({ heading, subheading, icon, benefits, button, learnMoreLink }) => {
    return (
        <div className="flex basis-1/2 flex-col justify-between gap-8 rounded-xl border border-border-light p-6">
            <div className="flex flex-col gap-4">
                {icon}
                <div className="flex flex-col">
                    <Heading4 className="text-xl font-normal">{heading}</Heading4>
                    <Text className="text-xl text-content-tertiary">{subheading}</Text>
                </div>
                <div className="flex flex-col gap-2 text-content-secondary">
                    {benefits.map(({ text, checked }, i) => (
                        <CheckedText key={i} text={text} checked={checked} />
                    ))}
                </div>
            </div>
            <div className="flex flex-col gap-4">
                {button}
                <a
                    href={learnMoreLink}
                    target="_blank"
                    rel="noopener noreferrer"
                    className="flex items-center justify-center gap-2 text-content-orange"
                    data-track-label="true"
                >
                    Learn more
                    <IconExternalLink size="sm" />
                </a>
            </div>
        </div>
    );
};

const IconStack: FC = () => {
    const className =
        "flex items-center justify-center rounded-lg border-0.5 border-border-light bg-surface-01 shadow-[0_0.6px_0.6px_0px_rgba(0,0,0,0.15)]";
    return (
        <div className="flex h-12 items-center">
            <div className={cn(className, "z-[10] h-12 w-12 p-1.5")}>
                <img src={awsLogo} />
            </div>
            <div className={cn(className, "z-[9] ml-[-8px] h-9 w-9 p-1 grayscale")}>
                <img src={azureLogo} />
            </div>
            <div className={cn(className, "z-[8] ml-[-8px] h-8 w-8 p-0.5 grayscale")}>
                <img src={gcpLogo} />
            </div>
            <div className={cn(className, "z-[7] ml-[-8px] h-7 w-7 p-0.5 grayscale")}>
                <img src={linuxLogo} />
            </div>
        </div>
    );
};

const SelectAProviderPage: FC<{ onSelectProvider: () => void; onContinueWithDesktop: () => void }> = ({
    onSelectProvider,
    onContinueWithDesktop,
}) => {
    let noCloudAccessCTA: React.ReactNode = (
        <span className="text-base text-content-tertiary">
            Download{" "}
            <a
                href={DESKTOP_APP_DOWNLOAD_URL}
                download
                className="text-content-orange"
                data-track-label="Download Gitpod Desktop"
            >
                Gitpod Desktop
            </a>{" "}
            to run locally.
        </span>
    );
    if (window.ipcRenderer) {
        noCloudAccessCTA = (
            <span className="text-base text-content-tertiary">
                <Button
                    variant={"link"}
                    className="p-0 font-normal text-content-orange"
                    onClick={onContinueWithDesktop}
                    data-track-label="true"
                >
                    Continue using Gitpod Desktop
                </Button>{" "}
                to run locally.
            </span>
        );
    }
    return (
        <OnboardingTabContent
            showDivider={false}
            data-track-location={TrackLocations.OnboardingRunnerSelectProviderTab}
        >
            <div className="flex flex-col gap-8 lg:flex-row lg:items-center">
                <div className="flex basis-1/2 flex-col gap-6">
                    <div className="flex flex-col">
                        <Heading4 className="text-xl font-normal">Select a provider</Heading4>
                    </div>
                    <div className="flex flex-col gap-2">
                        <ProviderOption name="AWS" iconImgSrc={awsLogo} onClick={onSelectProvider} />
                        <ProviderOption name="Azure" iconImgSrc={azureLogo} disabled />
                        <ProviderOption name="GCP" iconImgSrc={gcpLogo} disabled />
                        <ProviderOption name="Linux host-based" iconImgSrc={linuxLogo} disabled />
                    </div>
                    <Text className="text-center text-base text-content-tertiary">
                        No cloud access? {noCloudAccessCTA}
                    </Text>
                </div>
                <div className="flex basis-1/2 justify-center">
                    <VideoSection
                        playbackId="68VkfuJyAK026HPm00h5Zwhiii3unkF7Ao7DZSX4i01PlI"
                        metadataVideoTitle="Gitpod Flex - Runners (Flex)"
                        poster={setupRunnerImg}
                    />
                </div>
            </div>
        </OnboardingTabContent>
    );
};

const ProviderOption: FC<{ name: string; iconImgSrc: string; disabled?: boolean; onClick?: () => void }> = ({
    name,
    iconImgSrc,
    disabled,
    onClick,
}) => {
    const segmentTrack = useSegmentTrack();
    const [waitListJoined, setWaitListJoined] = useState(false);

    const onJoinWaitlist = useCallback(() => {
        segmentTrack("waitlist_joined", { provider: name, type: "infrastructure" });
        setWaitListJoined(true);
    }, [segmentTrack, setWaitListJoined, name]);

    const label = waitListJoined ? "Joined waitlist" : "Join waitlist";
    return (
        <div className="flex items-center justify-between rounded-xl border border-border-light p-2">
            <div className="flex items-center">
                <img src={iconImgSrc} className={cn("mr-3 h-8 w-8", disabled && "grayscale")} />
                <div className="text-base font-medium text-content-secondary">{name}</div>
            </div>
            {disabled ? (
                <Button
                    variant="link"
                    size="md"
                    onClick={onJoinWaitlist}
                    disabled={waitListJoined}
                    className={cn(waitListJoined && "disabled:bg-transparent disabled:text-content-green")}
                    data-track-label={`${label}: ${name}`}
                >
                    {label}
                </Button>
            ) : (
                <Button size="md" variant="secondary" onClick={onClick} data-track-label={`Select Runner: ${name}`}>
                    Select
                </Button>
            )}
        </div>
    );
};

const ConfigureYourInfrastructurePage: FC<{
    progress: RunnerConfigurationProgress;
    setProgress: (progress: RunnerConfigurationProgress) => void;
    onBack: () => void;
    onNext: () => void;
}> = ({ progress, setProgress, onBack, onNext }) => {
    const phases = useMemo(() => phasesFromProgress(progress), [progress]);

    return (
        <OnboardingTabContent showDivider={false} data-track-location={TrackLocations.OnboardingRunnerConfigureTab}>
            <div className="flex flex-col gap-4">
                <div className="flex flex-col gap-8">
                    <Timeline phases={phases} />
                    <TimelineContent
                        progress={progress}
                        setProgress={setProgress}
                        onComplete={onNext}
                        onBackToRunnerSelection={onBack}
                    />
                </div>
            </div>
        </OnboardingTabContent>
    );
};

const Loading: FC = () => {
    return (
        <div className="flex flex-col gap-4">
            <SkeletonBlock ready={false} className="h-14 w-full" />
            <SkeletonBlock ready={false} className="h-[300px] w-full" />
        </div>
    );
};
