import { IconApp } from "@/assets/icons/geist/IconApp";
import { IconMinimize } from "@/assets/icons/geist/IconDash";
import { Button } from "@/components/flexkit/Button";
import { DESKTOP_APP_DOWNLOAD_URL } from "@/desktop";
import { TrackLocations } from "@/hooks/use-segment";
import { useListPreferences, useSetPreference, type PlainUserPreference } from "@/queries/user-preferences-queries";
import { isMacLike } from "@/utils/platform";
import * as Popover from "@radix-ui/react-popover";
import { useCallback, useEffect, useState, type FC, type PropsWithChildren } from "react";

export const SHOW_NUDGE_DOWNLOAD_APP = "SHOW_NUDGE_DOWNLOAD_APP";

export const NudgeDownloadApp: FC<PropsWithChildren> = ({ children }) => {
    const { preferences, isPending: isPendingPreferences, error: preferencesError } = useListPreferences();
    const { mutate: setPreference } = useSetPreference();

    const [open, setOpen] = useState(false);

    // We disable the nudge if the user is not on a Mac or if the user is using the Desktop App already
    const disabled = !isMacLike() || Boolean(window.ipcRenderer);

    // Auto-show nudge if applicable
    useEffect(() => {
        // If we're still loading preferences, or if we failed to load them we default to not showing the nudge
        if (isPendingPreferences || preferencesError) {
            return;
        }

        if (showNudgeDownloadApp(preferences || [])) {
            setOpen(true);
            return;
        }
        setOpen(false);
    }, [isPendingPreferences, preferencesError, preferences, setOpen, disabled]);

    const toggleOpen = useCallback(() => {
        setOpen((previousOpenState) => {
            // if it was open (true), make it closed (false)
            // if it was closed (false), make it open (true)
            const isNowOpen = !previousOpenState;

            // if we are closing the nudge, save it to the backend
            if (!isNowOpen) {
                setPreference({
                    key: SHOW_NUDGE_DOWNLOAD_APP,
                    value: "false",
                });
            }

            return isNowOpen;
        });
    }, [setPreference]);

    return (
        <Popover.Root open={open}>
            <Popover.Trigger asChild type={"button"} disabled={disabled} data-testid="nudge-download-app-trigger">
                <Button
                    variant={"ghost"}
                    onClick={toggleOpen}
                    className="border-0 p-0 ring-transparent hover:bg-transparent"
                    data-track-label="true"
                >
                    {children}
                </Button>
            </Popover.Trigger>
            <Popover.Portal>
                <Popover.Content
                    side="top"
                    sideOffset={-32}
                    sticky="always"
                    onCloseAutoFocus={(e) => e.preventDefault()}
                    className="z-10 hidden will-change-[transform,opacity] data-[state=closed]:animate-slideDownAndFadeOut data-[state=open]:animate-slideUpAndFadeIn md:block"
                    data-track-location={TrackLocations.NudgeDownloadAppPopover}
                >
                    <Popover.Close
                        className="text-red fixed right-2 top-2 z-10 inline-flex h-6 w-6 outline-none ring-0 focus-visible:ring-0"
                        aria-label="Close"
                    >
                        <Button
                            variant="ghost"
                            size="sm"
                            className="border-0"
                            LeadingIcon={IconMinimize}
                            onClick={toggleOpen}
                            data-track-label="true"
                        />
                    </Popover.Close>
                    <div className="flex flex-col items-center gap-2">
                        <div className="flex min-w-[240px] flex-col rounded-2xl border-[0.5px] border-border-base/20 bg-surface-glass p-2 shadow-sm backdrop-blur-modal">
                            <div className="text-bold p-2 text-center text-base">
                                <p className="text-content-primary">No cloud access?</p>
                                <p className="text-content-secondary">Start locally on your Mac.</p>
                            </div>
                            <a href={DESKTOP_APP_DOWNLOAD_URL} download className="flex w-full">
                                <Button variant="primary" size="md" className="grow px-4" data-track-label="true">
                                    Download Gitpod Desktop
                                </Button>
                            </a>
                        </div>
                        <Button
                            variant={"ghost"}
                            onClick={toggleOpen}
                            className="h-[44px] w-[240px] cursor-default border-0 p-0 ring-transparent hover:bg-transparent"
                            LeadingIcon={IconApp}
                        />
                    </div>
                </Popover.Content>
            </Popover.Portal>
        </Popover.Root>
    );
};

// eslint-disable-next-line react-refresh/only-export-components
export function showNudgeDownloadApp(preferences: PlainUserPreference[]): boolean {
    if (!isMacLike() || Boolean(window.ipcRenderer)) {
        return false;
    }

    const savedPreference = preferences?.find((pref) => pref.key === SHOW_NUDGE_DOWNLOAD_APP);
    if (!savedPreference || savedPreference.value === "true") {
        return true;
    }

    return false;
}
