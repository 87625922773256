import type { Size } from "@/assets/icons/geist/Size";
import { type PropsWithClassName } from "@/components/podkit/lib/cn";
import type { FC } from "react";

export const IconCloud: FC<{ size: Size } & PropsWithClassName> = ({ size, className }) => {
    switch (size) {
        case "sm":
            return (
                <svg
                    className={className}
                    width="16"
                    height="16"
                    viewBox="0 0 16 16"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                >
                    <path
                        d="M14.9958 9.16752C14.9987 9.09785 15.0002 9.02785 15.0002 8.95752C15.0002 6.19609 12.7616 3.95752 10.0002 3.95752C7.80983 3.95752 5.94844 5.36593 5.27222 7.32652C3.12105 7.60201 1.4585 9.43977 1.4585 11.6659C1.4585 14.0821 3.41725 16.0409 5.8335 16.0409H15.1043C17.0028 16.0409 18.5418 14.5019 18.5418 12.6034C18.5418 10.7049 17.0028 9.16585 15.1043 9.16585C15.068 9.16585 15.0318 9.16644 14.9958 9.16752Z"
                        stroke="currentColor"
                        strokeWidth="1.25"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                    />
                </svg>
            );
        case "base":
            return (
                <svg
                    className={className}
                    width="20"
                    height="20"
                    viewBox="0 0 20 20"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                >
                    <path
                        d="M14.9958 9.16752C14.9987 9.09785 15.0002 9.02785 15.0002 8.95752C15.0002 6.19609 12.7616 3.95752 10.0002 3.95752C7.80983 3.95752 5.94844 5.36593 5.27222 7.32652C3.12105 7.60201 1.4585 9.43977 1.4585 11.6659C1.4585 14.0821 3.41725 16.0409 5.8335 16.0409H15.1043C17.0028 16.0409 18.5418 14.5019 18.5418 12.6034C18.5418 10.7049 17.0028 9.16585 15.1043 9.16585C15.068 9.16585 15.0318 9.16644 14.9958 9.16752Z"
                        stroke="currentColor"
                        strokeWidth="1.25"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                    />
                </svg>
            );
        case "lg":
            return (
                <svg
                    className={className}
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                >
                    <path
                        d="M14.9958 9.16752C14.9987 9.09785 15.0002 9.02785 15.0002 8.95752C15.0002 6.19609 12.7616 3.95752 10.0002 3.95752C7.80983 3.95752 5.94844 5.36593 5.27222 7.32652C3.12105 7.60201 1.4585 9.43977 1.4585 11.6659C1.4585 14.0821 3.41725 16.0409 5.8335 16.0409H15.1043C17.0028 16.0409 18.5418 14.5019 18.5418 12.6034C18.5418 10.7049 17.0028 9.16585 15.1043 9.16585C15.068 9.16585 15.0318 9.16644 14.9958 9.16752Z"
                        stroke="currentColor"
                        strokeWidth="1.25"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                    />
                </svg>
            );
    }
};
