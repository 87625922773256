import { useDocumentTitle } from "@/hooks/use-document-title";
import { type FC } from "react";
import { Heading2 } from "@/components/podkit/typography/Headings";
import { Text } from "@/components/podkit/typography/Text";
import { IconCloud } from "@/assets/icons/geist/IconCloud";
import type { PropsWithClassName } from "@/components/podkit/lib/cn";
import type { Size } from "@/assets/icons/geist/Size";
import { IconZap } from "@/assets/icons/geist/IconZap";
import { IconGrid } from "@/assets/icons/geist/IconGrid";
import { IconRunner } from "@/assets/icons/geist/IconRunner";
import { OnboardingTabContent } from "@/routes/onboarding/components/OnboardingTabContent";
import { VideoSection } from "@/routes/onboarding/components/VideoSection";
import { Button } from "@/components/flexkit/Button";
import howGitpodWorksImg from "@/assets/how-gitpod-works-thumbnail.jpg";
import { TrackLocations } from "@/hooks/use-segment";

type ContentSection = {
    title: string;
    description: string;
    Icon: React.ComponentType<{ size: Size } & PropsWithClassName>;
};

const contentSections: ContentSection[] = [
    {
        title: "Runners",
        description:
            "The infrastructure that runs your dev environments. Self-host in your cloud in under 3 minutes, or run Gitpod Desktop locally on your Mac.",
        Icon: IconRunner,
    },
    {
        title: "Dev container",
        description:
            "Your environment configurations, including all the tools, dependencies and access required for development. ",
        Icon: IconGrid,
    },
    {
        title: "Automations",
        description:
            "Reusable self-serve actions for automating common development workflows like seeding databases, provisioning infra, and configuring code assistants. ",
        Icon: IconZap,
    },
    {
        title: "Development environments",
        description:
            "Use your favorite editor or CLI to edit, compile, and run code in your automated, standardized development environment. ",
        Icon: IconCloud,
    },
];

const ContentSection: FC<ContentSection> = ({ Icon, title, description }) => (
    <div className="flex flex-col items-start justify-start self-stretch">
        <Heading2 className="flex items-center gap-1 self-stretch text-base font-medium text-content-primary">
            <Icon size={"base"} />
            {title}
        </Heading2>
        <Text className="text-base text-content-secondary">{description}</Text>
    </div>
);

export const HowGitpodWorksPage: FC<{ onNext: () => void }> = ({ onNext }) => {
    useDocumentTitle("Onboarding - How Gitpod Works");
    return (
        <OnboardingTabContent
            title="Start developing on any project in seconds"
            data-track-location={TrackLocations.OnboardingHowGitpodWorksTab}
        >
            <div className="flex flex-col gap-6">
                <div className="flex flex-col gap-8 lg:flex-row lg:items-center">
                    <div className="flex basis-1/2 flex-col items-start justify-start gap-6">
                        {contentSections.map((section, index) => (
                            <ContentSection key={index} {...section} />
                        ))}
                    </div>
                    <div className="flex basis-1/2 justify-center">
                        <VideoSection
                            playbackId="59ryp68i29e00y7Acv02xQU01OAQIPH8px65ij9wQS8zK4"
                            metadataVideoTitle="How Gitpod Flex works (Flex)"
                            poster={howGitpodWorksImg}
                        />
                    </div>
                </div>
                <div className="flex justify-end">
                    <Button onClick={onNext} variant="primary" size="md" data-track-label="true">
                        Setup a runner
                    </Button>
                </div>
            </div>
        </OnboardingTabContent>
    );
};
