import { IconMinimize } from "@/assets/icons/geist/IconDash";
import { showNudgeDownloadApp } from "@/components/NudgeDownloadApp";
import { Button } from "@/components/flexkit/Button";
import { TrackLocations } from "@/hooks/use-segment";
import { useListPreferences, useSetPreference } from "@/queries/user-preferences-queries";
import * as Popover from "@radix-ui/react-popover";
import { useCallback, useEffect, useState, type FC } from "react";

export const SHOW_REINVENT_BANNER = "SHOW_REINVENT_BANNER";

export const AnnouncementBanner: FC = () => {
    const { preferences, isPending: isPendingPreferences, error: preferencesError } = useListPreferences();
    const { mutate: setPreference } = useSetPreference();
    const [open, setOpen] = useState(false);

    // Auto-show nudge if applicable
    useEffect(() => {
        if (isPendingPreferences || preferencesError) {
            return;
        }

        // If the download app nudge is shown, we don't show the reinvent banner
        if (showNudgeDownloadApp(preferences || [])) {
            setOpen(false);
            return;
        }

        const savedPreference = preferences?.find((pref) => pref.key === SHOW_REINVENT_BANNER);
        if (!savedPreference || savedPreference.value === "true") {
            setOpen(true);
            return;
        }
        setOpen(false);
    }, [isPendingPreferences, preferencesError, preferences]);

    const toggleOpen = useCallback(() => {
        setOpen((previousOpenState) => {
            const isNowOpen = !previousOpenState;
            if (!isNowOpen) {
                setPreference({
                    key: SHOW_REINVENT_BANNER,
                    value: "false",
                });
            }
            return isNowOpen;
        });
    }, [setPreference]);

    return (
        <Popover.Root open={open}>
            <Popover.Trigger data-testid="announcement-banner-trigger" />
            <Popover.Portal>
                <Popover.Content
                    side="top"
                    sideOffset={-32}
                    sticky="always"
                    onCloseAutoFocus={(e) => e.preventDefault()}
                    className="z-10 hidden max-w-[220px] will-change-[transform,opacity] data-[state=closed]:animate-slideDownAndFadeOut data-[state=open]:animate-slideUpAndFadeIn md:block"
                    data-track-location={TrackLocations.AnnouncementBannerPopover}
                >
                    <Popover.Close className="text-red fixed -right-1 top-3 z-10 inline-flex h-6 w-6 outline-none ring-0 focus-visible:ring-0">
                        <Button
                            variant="ghost"
                            size="sm"
                            className="border-0"
                            LeadingIcon={IconMinimize}
                            onClick={toggleOpen}
                            data-track-label="Announcement - AWS Re:Invent - Dismiss"
                        />
                    </Popover.Close>
                    <div className="flex flex-col items-center gap-2">
                        <div className="flex min-w-[240px] flex-col rounded-2xl border-[0.5px] border-border-base/20 bg-surface-glass p-2 shadow-sm backdrop-blur-modal">
                            <div className="text-bold p-2 text-center text-base">
                                <p className="text-content-primary">See you at re:Invent!</p>
                                <p className="text-content-secondary">
                                    Book a demo with us, and join our developer productivity leaders roundtable (limited
                                    tickets)
                                </p>
                            </div>
                            <a
                                href="https://www.gitpod.io/aws-reinvent-24"
                                target="_blank"
                                className="flex w-full"
                                rel="noreferrer"
                            >
                                <Button
                                    variant="brand"
                                    size="md"
                                    className="grow px-4"
                                    data-track-label="Announcement - AWS Re:Invent - Learn more"
                                >
                                    Learn more
                                </Button>
                            </a>
                        </div>
                    </div>
                </Popover.Content>
            </Popover.Portal>
        </Popover.Root>
    );
};
