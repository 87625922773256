import { DialogBody, DialogDescription, DialogHeader, DialogTitle } from "@/components/podkit/modal/Modal";
import { type Provider } from "@/components/runners/details/SourceControlProvider";
import { SourceControlProviderIcon } from "@/components/runners/details/SourceControlProviderIcon";
import { Text } from "@/components/podkit/typography/Text";
import { useCallback, useState, type FC } from "react";
import { Button } from "@/components/flexkit/Button";
import { useSegmentTrack } from "@/hooks/use-segment";
import { cn } from "@/components/podkit/lib/cn";

export const SourceControlProviderAddModal: FC<{
    providers: Provider[];
    onProviderCreate: (provider: Provider) => void;
}> = ({ providers, onProviderCreate: onProviderCreate }) => {
    return (
        <>
            <DialogHeader>
                <DialogTitle>Select a provider</DialogTitle>
                <DialogDescription />
            </DialogHeader>

            <DialogBody>
                <div className="flex flex-col gap-2">
                    {providers.map((provider) => (
                        <ProviderRow
                            key={provider.schema.scmId}
                            provider={provider}
                            onProviderCreate={onProviderCreate}
                            readOnly={false}
                        />
                    ))}
                </div>
            </DialogBody>
        </>
    );
};

export const ProviderRow: FC<{
    readOnly: boolean;
    provider: Provider;
    onProviderCreate: (provider: Provider) => void;
}> = ({ provider, onProviderCreate, readOnly }) => {
    return (
        <div className="flex w-full items-center justify-between rounded-xl border-0.5 border-border-base bg-surface-secondary px-3 py-2">
            <div className="flex items-center gap-3">
                <div className="flex size-8 items-center justify-center rounded-lg">
                    <SourceControlProviderIcon scmId={provider.schema.scmId} />
                </div>
                <Text className="text-base font-bold">{provider.name}</Text>
            </div>
            <ProviderStatus readOnly={readOnly} provider={provider} onProviderCreate={onProviderCreate} />
        </div>
    );
};

const ProviderStatus: FC<{ provider: Provider; readOnly: boolean; onProviderCreate: (provider: Provider) => void }> = ({
    provider,
    onProviderCreate,
    readOnly,
}) => {
    const segmentTrack = useSegmentTrack();
    const [waitListJoined, setWaitListJoined] = useState(false);

    const onJoinWaitlist = useCallback(() => {
        segmentTrack("waitlist_joined", { provider: provider.name, type: "git_provider" });
        setWaitListJoined(true);
    }, [segmentTrack, setWaitListJoined, provider]);

    const label = waitListJoined ? "Joined waitlist" : "Join waitlist";
    switch (provider.state) {
        case "coming-soon":
            return (
                <Button
                    variant="link"
                    size="md"
                    onClick={onJoinWaitlist}
                    disabled={waitListJoined}
                    className={cn(waitListJoined && "disabled:bg-transparent disabled:text-content-green")}
                    data-track-label={`${label}: ${provider.name}`}
                >
                    {label}
                </Button>
            );
        case "available":
            return (
                <Button
                    variant="secondary"
                    onClick={() => onProviderCreate(provider)}
                    data-testid={`select-${provider.schema.scmId}`}
                    disabled={readOnly}
                    data-track-label={`Select SCM: ${provider.name}`}
                >
                    Select
                </Button>
            );
    }
};
