import { Button, type ButtonProps } from "@/components/flexkit/Button";
import { useToast } from "@/components/podkit/toasts/use-toast";
import { Tooltip } from "@/components/Tooltip";
import { TrackLocations } from "@/hooks/use-segment";
import {
    type CheckScmAuthForProjectResult,
    getRepoUrlFromInitializer,
    type PlainRunnerEnvironmentClass,
    useCheckScmAuthForProject,
    useCreateEnvironmentFromProject,
} from "@/queries/environment-queries";
import type { PlainProject } from "@/queries/project-queries";
import { SCMAuthenticationModal } from "@/routes/environments/create/SCMAuthentication";
import { getDetailsURL } from "@/routes/environments/details-url";
import { formatError } from "@/utils/errors";
import { type FC, forwardRef, useCallback, useState } from "react";
import { useNavigate } from "react-router-dom";

type Props = {
    project?: PlainProject;
    onCreateSuccess?: () => void;
    hideTooltip?: boolean;
} & ButtonProps;

export const CreateEnvironmentButton = forwardRef<HTMLButtonElement, Props>(
    ({ project, onCreateSuccess, hideTooltip, ...props }, ref) => {
        const disabled = !project;

        const { toast } = useToast();
        const navigate = useNavigate();

        const createEnvironment = useCreateEnvironmentFromProject();
        const checkAuth = useCheckScmAuthForProject();

        const [showScmAuthModal, setShowScmAuthModal] = useState<
            (CheckScmAuthForProjectResult & { project: PlainProject }) | undefined
        >();
        const doCreateEnvironment = useCallback(
            async (project: PlainProject) => {
                try {
                    const environment = await createEnvironment.mutateAsync({
                        project,
                    });
                    toast({ title: "Created new environment" });
                    onCreateSuccess?.();
                    navigate(getDetailsURL(environment));
                    setShowScmAuthModal(undefined);
                } catch (error) {
                    toast({ title: "Failed to create an environment", description: formatError(error) });
                }
            },
            [createEnvironment, navigate, onCreateSuccess, toast],
        );

        const handleCreateEnvironmentFromProject = useCallback(
            async (project: PlainProject) => {
                // 1. if not authenticated, we need to show auth before creating the environment
                try {
                    const result = await checkAuth.mutateAsync({ project });
                    const requiresAuth = result && !result.authenticated;
                    if (requiresAuth) {
                        setShowScmAuthModal({ ...result, project });
                        return;
                    }
                } catch (error) {
                    toast({ title: "Failed to create an environment", description: formatError(error) });
                    return;
                }

                // 2. if auth check was ok, let's create the environment
                await doCreateEnvironment(project);
            },
            [doCreateEnvironment, checkAuth, toast],
        );

        return (
            <>
                <Tooltip content={hideTooltip ? "" : "Create environment"}>
                    <Button
                        {...props}
                        ref={ref}
                        disabled={disabled}
                        loading={checkAuth.isPending || createEnvironment.isPending}
                        onClick={() => project && handleCreateEnvironmentFromProject(project)}
                    />
                </Tooltip>
                {showScmAuthModal && (
                    <AuthenticationModal
                        {...showScmAuthModal}
                        repoURL={getRepoUrlFromInitializer(showScmAuthModal.project.initializer) || ""}
                        onClose={() => setShowScmAuthModal(undefined)}
                        onAuthSuccess={() => doCreateEnvironment(showScmAuthModal.project)}
                    />
                )}
            </>
        );
    },
);
CreateEnvironmentButton.displayName = "CreateEnvironmentButton";

type SCMAuthenticationModalProps = {
    authenticationUrl: string;
    patSupported: boolean;
    scmId: string;
    environmentClass: PlainRunnerEnvironmentClass;
    repoURL: string;
    onClose: () => void;
    onAuthSuccess: () => void;
};
const AuthenticationModal: FC<SCMAuthenticationModalProps> = (p) => {
    return (
        <div>
            <SCMAuthenticationModal
                repoURL={p.repoURL}
                authenticationUrl={p.authenticationUrl}
                patSupported={p.patSupported}
                scmId={p.scmId}
                onClose={p.onClose}
                onClickContinue={p.onAuthSuccess}
                clazz={p.environmentClass}
                data-track-location={TrackLocations.CreateEnvironmentSCMAuthenticationModal}
            />
        </div>
    );
};
