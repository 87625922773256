import { useCallback, useState, type FC, type PropsWithChildren } from "react";
import { type RunnerConfigurationProgress } from "@/routes/onboarding/use-runner-configuration-progress";
import { SourceControlProvider } from "@/components/runners/details/SourceControlProvider";
import { useCreateRemoteRunner, useRunner } from "@/queries/runner-queries";
import {
    useListRunnerEnvironmentClasses,
    useListRunnerSCMIntegrations,
    useRunnerConfigurationSchema,
} from "@/queries/runner-configuration-queries";
import { EnvironmentClasses } from "@/components/runners/details/EnvironmentClasses";
import { RunnerPhase } from "gitpod-next-api/gitpod/v1/runner_pb";
import { NewRunnerForm } from "@/components/runners/NewRunnerForm";
import { Heading2 } from "@/components/podkit/typography/Headings";
import { Text } from "@/components/podkit/typography/Text";
import { Button } from "@/components/flexkit/Button";
import { CloudFormationStackContent } from "@/components/runners/details/CloudFormationStack";
import { createRunnerSetupURL } from "@/components/runners/RunnerSetupURL";
import { useToast } from "@/components/podkit/toasts/use-toast";
import { IconExternalLink } from "@/assets/icons/geist/IconExternalLink";
import { ExternalLink } from "@/components/podkit/typography/Link";
import type { SectionStatus } from "@/components/runners/details/RunnerDetailsSection";
import { TrackLocations, type TrackLocation } from "@/hooks/use-segment";

export const TimelineContent: FC<{
    progress: RunnerConfigurationProgress;
    setProgress: (progress: RunnerConfigurationProgress) => void;
    onComplete: () => void;
    onBackToRunnerSelection: () => void;
}> = ({ progress, setProgress, onComplete, onBackToRunnerSelection }) => {
    const { data: runner } = useRunner(progress.runnerId);
    const { data: schema, isPending: schemaIsPending } = useRunnerConfigurationSchema(runner?.runnerId);
    const { data: integrations, isPending: integrationIsPending } = useListRunnerSCMIntegrations(runner?.runnerId);
    const { data: environmentClasses, isPending: envClassIsPending } = useListRunnerEnvironmentClasses(
        runner?.runnerId,
    );

    const hasStack = runner?.status?.phase !== RunnerPhase.CREATED;

    switch (progress.id) {
        case "install-runner":
            if (!progress.runnerId) {
                return (
                    <TimelineContentCreateRunner
                        onBack={onBackToRunnerSelection}
                        onCreate={(runnerId: string) => setProgress({ id: "install-runner", runnerId })}
                    />
                );
            } else {
                return (
                    <TimelineContentContainer
                        onBack={() => setProgress({ id: "install-runner", runnerId: undefined })}
                        nextTitle="Configure Git provider"
                        onNextDisabled={!hasStack}
                        onNext={() => setProgress({ id: "configure-scm", runnerId: progress.runnerId || "" })}
                        data-track-location={TrackLocations.OnboardingRunnerConfigureCloudFormationStackTabStep}
                    >
                        <CloudFormationStackContent
                            runner={runner}
                            hasStack={hasStack}
                            className="rounded-lg border-0.5 border-border-base bg-transparent"
                        />
                    </TimelineContentContainer>
                );
            }
        case "configure-scm":
            if (runner && !schemaIsPending && !integrationIsPending) {
                return (
                    <TimelineContentContainer
                        title="Configure Git provider"
                        description="Securely access your repos while keeping your code, data, and IP within your private network. All authentication happens on your infrastructure, ensuring maximum security and control."
                        onBack={() => setProgress({ id: "install-runner", runnerId: runner.runnerId })}
                        nextTitle="Select environment classes"
                        onNext={() => setProgress({ id: "configure-environment-classes", runnerId: runner.runnerId })}
                        onNextDisabled={!integrations?.length}
                        docsLink="https://www.gitpod.io/docs/flex/source-control"
                        data-track-location={TrackLocations.OnboardingRunnerConfigureSCMTabStep}
                    >
                        <SourceControlProvider
                            hasStack={hasStack}
                            runner={runner}
                            schemas={schema?.scm}
                            Container={DivContainer}
                            withDescription={false}
                            data-track-location={(modalState) =>
                                modalState.type == "add-provider"
                                    ? TrackLocations.OnboardingRunnerAddSCMProviderModal
                                    : modalState.type == "remove-provider"
                                      ? TrackLocations.OnboardingRunnerDeleteSCMProviderModal
                                      : TrackLocations.OnboardingRunnerEditSCMProviderModal
                            }
                        />
                    </TimelineContentContainer>
                );
            }
            break;
        case "configure-environment-classes":
            if (runner && !schemaIsPending && !envClassIsPending) {
                return (
                    <TimelineContentContainer
                        title="Select environment classes"
                        description="Add or restrict machine sizes available for environments launched on this runner."
                        nextTitle="Finish runner setup"
                        onNext={onComplete}
                        onBack={() => setProgress({ id: "configure-scm", runnerId: runner.runnerId })}
                        onNextDisabled={!environmentClasses?.length}
                        data-track-location={TrackLocations.OnboardingRunnerConfigureEnvironmentClassesTabStep}
                    >
                        <EnvironmentClasses
                            runner={runner}
                            schema={schema?.environmentClasses}
                            hasStack={hasStack}
                            Container={DivContainer}
                            withDescription={false}
                            data-track-location={({ type }) =>
                                type === "add"
                                    ? TrackLocations.OnboardingRunnerAddEnvironmentClassModal
                                    : TrackLocations.OnboardingRunnerEditEnvironmentClassModal
                            }
                        />
                    </TimelineContentContainer>
                );
            }
            break;
    }
};

const DivContainer: FC<{ status: SectionStatus } & PropsWithChildren> = ({ children }) => {
    return <div>{children}</div>;
};

const TimelineContentCreateRunner: FC<{ onBack: () => void; onCreate: (runnerId: string) => void }> = ({
    onBack,
    onCreate,
}) => {
    const createRunner = useCreateRemoteRunner();
    const { toast } = useToast();
    const [disabled, setDisabled] = useState(false);
    const handleCreateRunner = useCallback<(values: { name: string; region: string }) => Promise<void>>(
        async ({ name, region }) => {
            const { runner, accessToken } = await createRunner.mutateAsync({ name, region });
            const setupURL = createRunnerSetupURL(runner, accessToken, region || "");
            window.open(setupURL, "awsWindow", "popup");
            toast({
                title: "Opened AWS CloudFormation Stack URL",
                description: (
                    <Text>
                        If the window was blocked by your browser, you can use{" "}
                        <ExternalLink href={setupURL}>this link</ExternalLink> instead.
                    </Text>
                ),
            });
            onCreate(runner.runnerId);
        },
        [toast, createRunner, onCreate],
    );

    return (
        <TimelineContentContainer data-track-location={TrackLocations.OnboardingRunnerCreateRunnerTabStep}>
            <div className="flex flex-col items-start gap-8">
                <div className="w-full rounded-lg border-0.5 p-4 pr-6">
                    <NewRunnerForm
                        formId="new-remote-runner"
                        onDisabledChange={setDisabled}
                        onSubmit={handleCreateRunner}
                        className="*:flex-grow lg:flex-row"
                    />
                </div>
                <div className="max-w-[649px]">
                    <Text className="text-base text-content-secondary">
                        <p>
                            The CloudFormation template installs an ECS service, an S3 bucket, IAM roles and
                            (optionally) security groups for running development environments.
                        </p>
                        <p className="mt-4">
                            Open a pre-populated CloudFormation template to finish the runner installation.
                        </p>
                    </Text>
                </div>
                <div className="flex gap-2 self-end">
                    <Button variant="secondary" onClick={onBack} data-track-label="true">
                        Back
                    </Button>
                    <Button
                        type="submit"
                        form="new-remote-runner"
                        variant="primary"
                        disabled={disabled}
                        loading={createRunner.isPending}
                        LeadingIcon={IconExternalLink}
                        data-track-label="true"
                    >
                        Open AWS CloudFormation
                    </Button>
                </div>
            </div>
        </TimelineContentContainer>
    );
};

const TimelineContentContainer: FC<{
    title?: string;
    description?: string;
    onBack?: () => void;
    nextTitle?: string;
    onNext?: () => void;
    onNextDisabled?: boolean;
    children: React.ReactNode;
    docsLink?: string;
    "data-track-location": TrackLocation;
}> = ({
    onBack,
    onNext,
    nextTitle,
    onNextDisabled,
    children,
    title,
    description,
    docsLink,
    "data-track-location": dataTrackLocation,
}) => {
    return (
        <div className="flex flex-col gap-4" data-track-location={dataTrackLocation}>
            {title || description ? (
                <div className="flex items-start justify-between">
                    <div className="max-w-2xl">
                        <Heading2 className="text-xl font-medium text-content-primary">{title}</Heading2>
                        <Text className="text-base text-content-secondary">{description}</Text>
                    </div>
                    {docsLink && (
                        <ExternalLink href={docsLink} className="flex items-center gap-2 text-content-orange">
                            Docs
                            <IconExternalLink size="sm" />
                        </ExternalLink>
                    )}
                </div>
            ) : null}
            <div className="w-full">{children}</div>
            {onBack || onNext ? (
                <div className="flex gap-2 self-end">
                    {onBack && (
                        <Button variant="secondary" onClick={onBack} data-track-label="true">
                            Back
                        </Button>
                    )}
                    {onNext && (
                        <Button variant="primary" onClick={onNext} disabled={onNextDisabled} data-track-label="true">
                            {nextTitle || "Next"}
                        </Button>
                    )}
                </div>
            ) : null}
        </div>
    );
};
