import { useCallback, useState, type FC, type PropsWithChildren } from "react";
import { Heading2 } from "@/components/podkit/typography/Headings";
import { Text } from "@/components/podkit/typography/Text";
import { useListSecrets } from "@/queries/secret-queries";
import { SkeletonBlock } from "@/components/podkit/loading/Skeleton";
import { Button } from "@/components/flexkit/Button";
import { ErrorMessage } from "@/components/ErrorMessage";
import { SecretsTable } from "@/components/projects/SecretsTable";
import { NewSecretModal } from "@/components/projects/NewSecretModal";

export const ProjectSecrets: FC<{ projectId: string }> = ({ projectId }) => {
    const { data: secrets, isPending, error } = useListSecrets(projectId);

    const [showAddSecretModal, setShowAddSecretModal] = useState(false);

    const onNewSecret = useCallback(() => {
        setShowAddSecretModal(true);
    }, [setShowAddSecretModal]);

    return (
        <div className="flex flex-col gap-2">
            <div className="flex justify-between">
                <div className="flex flex-col">
                    <Heading2 className="text-base font-bold text-content-primary">Secrets</Heading2>
                </div>
            </div>

            <SkeletonBlock ready={!isPending} failed={!!error}>
                <ErrorMessage error={error} />
                <SecretsCard>
                    {secrets?.secrets?.length ? (
                        <SecretsTable secrets={secrets?.secrets} onNewSecret={onNewSecret} />
                    ) : (
                        <SecretsEmptyState onNewSecret={onNewSecret} />
                    )}
                </SecretsCard>
            </SkeletonBlock>

            {showAddSecretModal && (
                <NewSecretModal projectId={projectId} onClose={() => setShowAddSecretModal(false)} />
            )}
        </div>
    );
};

const SecretsCard: FC<PropsWithChildren> = ({ children }) => {
    return (
        <div className="flex w-full max-w-[658px] justify-center rounded-xl border border-border-base bg-surface-glass px-4 py-3">
            {children}
        </div>
    );
};

const SecretsEmptyState: FC<{ onNewSecret: () => void }> = ({ onNewSecret }) => {
    return (
        <div className="flex flex-col gap-2 px-4 py-8" data-testid="secrets-empty-state">
            <Text className="text-base text-content-tertiary">This project has no secrets.</Text>
            <Button
                variant="secondary"
                size={"md"}
                data-track-label="true"
                onClick={() => onNewSecret()}
                data-testid="add-secret-modal-trigger"
            >
                New secret
            </Button>
        </div>
    );
};
