import { IconBook } from "@/assets/icons/geist/IconBook";
import { IconGauge } from "@/assets/icons/geist/IconGauge";
import { SidebarButton } from "@/components/SidebarButton";
import { useOnboarding } from "@/hooks/use-onboarding";
import { type FC } from "react";
import { useLocation } from "react-router-dom";

export const OnboardingSidebarSection: FC = () => {
    const location = useLocation();
    const onboarding = useOnboarding();

    const completed = onboarding.steps.steps.filter((step) => step.completed).length;

    const pathname = "/onboarding/enabler";

    return (
        <SidebarButton
            icon={<IconBook size="lg" />}
            iconRight={<IconGauge size="lg" completed={completed} />}
            label="Getting started"
            active={location.pathname === pathname}
            to={pathname}
        />
    );
};
