import { useGitpodAPI } from "@/hooks/use-gitpod-api";
import { defaultRetry, defaultThrowOnError } from "@/queries/errors";
import { keyWithPrincipal } from "@/queries/principal-key";
import { useAuthenticatedUser } from "@/queries/user-queries";
import { toPlainMessage, type PlainMessage } from "@bufbuild/protobuf";
import { useMutation, useQuery, useQueryClient, type QueryClient } from "@tanstack/react-query";
import { type Secret } from "gitpod-next-api/gitpod/v1/secret_pb";

export type PlainSecret = PlainMessage<Secret>;

export type SecretMountType = Secret["mount"];

export function toPlainProjectPolicy(secret: Secret): PlainSecret {
    return toPlainMessage(secret);
}

export const keys = {
    list: (projectId?: string) => keyWithPrincipal(["secrets", "list", projectId]),
};

export const useListSecrets = (projectId?: string) => {
    const api = useGitpodAPI();
    const { data: user } = useAuthenticatedUser();

    return useQuery({
        queryKey: keys.list(projectId),
        queryFn: async () => {
            if (!user) {
                throw new Error("User not authenticated");
            }

            const { secrets, pagination } = await api.secretService.listSecrets({
                filter: {
                    projectIds: projectId ? [projectId] : [],
                },
                pagination: {
                    pageSize: 100,
                },
            });

            return {
                secrets: secrets.map(toPlainProjectPolicy),
                pagination,
            };
        },
        throwOnError: defaultThrowOnError,
        retry: defaultRetry,
        enabled: !!user && !!projectId,
        staleTime: 200,
    });
};

export const useCreateSecret = () => {
    const api = useGitpodAPI();
    const client = useQueryClient();
    const { data: user } = useAuthenticatedUser();

    return useMutation({
        mutationFn: async ({
            projectId,
            secretName,
            plaintextValue,
            mount,
        }: {
            projectId: string;
            secretName: string;
            plaintextValue: string;
            mount: SecretMountType;
        }) => {
            if (!user) {
                throw new Error("User not authenticated");
            }

            const { secret } = await api.secretService.createSecret({
                name: secretName,
                projectId: projectId,
                value: plaintextValue,
                mount: mount,
            });

            if (secret) {
                setSecretInCache(client, projectId, toPlainProjectPolicy(secret));
            }
        },
        throwOnError: defaultThrowOnError,
    });
};

export const useUpdateSecretValue = () => {
    const api = useGitpodAPI();
    const { data: user } = useAuthenticatedUser();

    return useMutation({
        mutationFn: async ({ secretId, plaintextValue }: { secretId: string; plaintextValue: string }) => {
            if (!user) {
                throw new Error("User not authenticated");
            }

            await api.secretService.updateSecretValue({
                secretId: secretId,
                value: plaintextValue,
            });
        },
        throwOnError: defaultThrowOnError,
    });
};

export const useDeleteSecret = () => {
    const api = useGitpodAPI();
    const client = useQueryClient();
    const { data: user } = useAuthenticatedUser();

    return useMutation({
        mutationFn: async ({ projectId, secretId }: { projectId: string; secretId: string }) => {
            if (!user) {
                throw new Error("User not authenticated");
            }

            await api.secretService.deleteSecret({
                secretId: secretId,
            });
            removeSecretFromCache(client, projectId, secretId);
        },
        throwOnError: defaultThrowOnError,
    });
};

function removeSecretFromCache(client: QueryClient, projectId: string, secretId: string) {
    client.setQueryData(keys.list(projectId), (currentData?: { secrets: PlainSecret[] }) => {
        if (!currentData) {
            return [];
        }

        return {
            ...currentData,
            secrets: currentData.secrets.filter((s) => s?.id !== secretId),
        };
    });
}

function setSecretInCache(client: QueryClient, projectId: string, secret: PlainSecret) {
    client.setQueryData(keys.list(projectId), (currentData?: { secrets: PlainSecret[] }) => {
        if (!currentData) {
            return [secret];
        }

        return {
            ...currentData,
            secrets: currentData.secrets
                .filter((s) => s?.id !== secret.id)
                .concat([secret])
                .filter((w) => !!w),
        };
    });
}
