import type React from "react";
import { type FC, useCallback, useState, useEffect } from "react";
import gitpodLogo from "@/assets/gitpod-logo.svg";
import { Heading3 } from "@/components/podkit/typography/Headings";
import { Button } from "@/components/flexkit/Button";
import { useGetAccount } from "@/queries/account-queries";
import { useDocumentTitle } from "@/hooks/use-document-title";
import type { PlainMessage } from "@bufbuild/protobuf";
import type { Account } from "gitpod-next-api/gitpod/v1/account_pb";
import { useBootIntercom } from "@/hooks/use-intercom";

type SigninDesktopState = "authentication-pending" | "authentication-complete" | "token-expired";

type SigninDesktopPageProps = {
    timeout?: number;
};

export const SigninDesktopPage: FC<SigninDesktopPageProps> = ({ timeout }) => {
    useDocumentTitle("Gitpod Signin");
    useBootIntercom();

    const { data: account } = useGetAccount();
    const [state, setState] = useState<SigninDesktopState>("authentication-pending");

    useEffect(() => {
        const timer = setTimeout(
            () => {
                if (state == "authentication-pending") {
                    setState("token-expired");
                }
            },
            timeout || 5 * 60 * 1000,
        );
        return () => {
            clearTimeout(timer);
        };
    }, [state, setState, timeout]);

    const handleConfirm = useCallback(() => {
        window.location.href = "/auth/desktop/redirect";
        setState("authentication-complete");
    }, [setState]);

    return <SigninDesktop state={state} account={account} onConfirm={handleConfirm} />;
};

type SigninDesktopProps = {
    account: PlainMessage<Account> | null | undefined;
    state: SigninDesktopState;
    onConfirm: () => void;
};

export const SigninDesktop: FC<SigninDesktopProps> = ({ account, state, onConfirm }) => {
    let content: React.ReactNode;
    if (state == "authentication-pending") {
        content = (
            <>
                <p>There is one more step.</p>
                <div className="my-8 flex flex-col items-center p-6">
                    <Button id="complete" onClick={() => onConfirm()} data-testid="complete">
                        Complete authorization in application
                    </Button>
                </div>
            </>
        );
    } else if (state == "authentication-complete") {
        content = (
            <div className="my-8 flex flex-col items-center p-6">
                <p className="text-xs text-gray-500" data-testid="completed-text">
                    You may now close this page.
                </p>
            </div>
        );
    } else if (state == "token-expired") {
        content = (
            <div className="my-8 flex flex-col items-center p-6">
                <p className="text-xs text-gray-500" data-testid="expired-text">
                    The authorization token has expired. Please close this window and retry the sign-in flow again.
                </p>
            </div>
        );
    }

    return (
        <div className="m-8 mt-16 flex flex-col items-center">
            <img src={gitpodLogo} className="mb-4" alt="Gitpod logo" width="45" height="45" />
            <Heading3 asChild>{account && <h1>Hello, {account.email}</h1>}</Heading3>
            {content}
        </div>
    );
};
