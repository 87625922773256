import { IconCheckFill } from "@/assets/icons/geist/IconCheckFill";
import { Tabs, TabsContent, TabsList, TabsTrigger } from "@/components/podkit/tabs/Tabs";
import { Heading1, Subheading } from "@/components/podkit/typography/Headings";
import { OnboardingStepId, useOnboarding } from "@/hooks/use-onboarding";
import { AutomateYourDevEnvironmentPage } from "@/routes/onboarding/AutomateYourDevEnvironmentPage";
import { HowGitpodWorksPage } from "@/routes/onboarding/HowGitpodWorksPage";
import { InvitePeoplePage } from "@/routes/onboarding/InvitePeoplePage";
import { SetupARunnerPage } from "@/routes/onboarding/SetupARunnerPage";
import { useEffect, useRef, useState, type FC } from "react";

export const OnboardingContent: FC = () => {
    const onboarding = useOnboarding();
    const [selectedStep, setSelectedStep] = useState(OnboardingStepId.HowGitpodWorks);

    const calledOnce = useRef(false);
    useEffect(() => {
        if (calledOnce.current) {
            return;
        }
        if (!onboarding.steps.isLoading) {
            calledOnce.current = true;
            setSelectedStep(onboarding.steps.defaultActive);
        }
    }, [onboarding]);

    if (onboarding.steps.isLoading) {
        return;
    }

    return (
        <div className="flex flex-col gap-6 pl-2 pt-2">
            <div className="flex flex-col gap-1">
                <Heading1 className="flex flex-row align-middle text-content-primary">Getting started</Heading1>
                <Subheading className="font-normal">
                    You are on your way to your first one-click development environment
                </Subheading>
            </div>
            <Tabs defaultValue={selectedStep} value={selectedStep}>
                <div className="flex flex-col gap-6">
                    <TabsList>
                        {onboarding.steps.steps.map((step, idx) => (
                            <TabsTrigger
                                key={step.id}
                                value={step.id}
                                onClick={() => setSelectedStep(step.id)}
                                disabled={idx > 0 && onboarding.steps.steps[idx - 1]?.completed === false}
                                className="shrink-0"
                                data-track-label={`${idx + 1}. ${step.title}`}
                            >
                                <div className="flex items-center justify-center gap-1 font-medium">
                                    {`${idx + 1}. ${step.title}`}
                                    {step.completed && <IconCheckFill size="base" className="text-content-green" />}
                                </div>
                            </TabsTrigger>
                        ))}
                    </TabsList>
                    <TabsContent value={OnboardingStepId.HowGitpodWorks}>
                        <HowGitpodWorksPage
                            onNext={() => {
                                onboarding.complete(OnboardingStepId.HowGitpodWorks);
                                setSelectedStep(OnboardingStepId.SetUpARunner);
                            }}
                        />
                    </TabsContent>
                    <TabsContent value={OnboardingStepId.SetUpARunner}>
                        <SetupARunnerPage
                            onBack={() => setSelectedStep(OnboardingStepId.HowGitpodWorks)}
                            onNext={() => {
                                onboarding.complete(OnboardingStepId.SetUpARunner);
                                setSelectedStep(OnboardingStepId.AutomateYourDevEnvironment);
                            }}
                        />
                    </TabsContent>
                    <TabsContent value={OnboardingStepId.AutomateYourDevEnvironment}>
                        <AutomateYourDevEnvironmentPage
                            onNext={() => {
                                onboarding.complete(OnboardingStepId.AutomateYourDevEnvironment);
                            }}
                            onBack={() => setSelectedStep(OnboardingStepId.SetUpARunner)}
                        />
                    </TabsContent>
                    <TabsContent value={OnboardingStepId.ShareGoldenPathWithYourTeam}>
                        <InvitePeoplePage />
                    </TabsContent>
                </div>
            </Tabs>
        </div>
    );
};
