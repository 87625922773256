import { Button } from "@/components/flexkit/Button";
import { DropdownMenuItem, DropdownMenuSeparator } from "@/components/podkit/dropdown/DropDown";
import { DropdownActions } from "@/components/podkit/dropdown/DropDownActions";
import { cn } from "@/components/podkit/lib/cn";
import { useToast } from "@/components/podkit/toasts/use-toast";
import { getRepoUrlFromInitializer } from "@/queries/environment-queries";
import type { PlainOrganizationMember } from "@/queries/organization-queries";
import type { PlainProject, ProjectState } from "@/queries/project-queries";
import { CreateEnvironmentButton } from "@/routes/environments/create/CreateEnvironmentButton";
import { DeleteProjectModal } from "@/routes/projects/ProjectDeleteModal";
import { coalesce } from "@/utils/arrays";
import { formatError } from "@/utils/errors";
import { OrganizationRole } from "gitpod-next-api/gitpod/v1/organization_pb";
import { useCallback, useState, type FC } from "react";
import { useNavigate } from "react-router-dom";

type Props = {
    canEdit: boolean;
    project: PlainProject;
    state: ProjectState;
    orgMembers?: PlainOrganizationMember[];
    currentUserId: string;
    forceHoverState?: boolean;
    onCreateSuccess?: () => void;
};
export const ProjectCard: FC<Props> = ({
    canEdit,
    project,
    state,
    orgMembers,
    currentUserId,
    forceHoverState,
    onCreateSuccess,
}) => {
    const navigate = useNavigate();
    const projectContextUrl = getRepoUrlFromInitializer(project.initializer);
    const totalUsedBy = project.usedBy?.totalSubjects || 1;

    const creator = orgMembers?.find((m) => m.userId === project.metadata?.creator?.id);
    let users = coalesce(project.usedBy?.subjects?.map((s) => orgMembers?.find((m) => m.userId === s.id)) || []).slice(
        0,
        5,
    );
    users = users.length ? users : creator ? [creator] : [];
    const currentMember = orgMembers?.find((m) => m.userId === currentUserId);
    const isAdmin = currentMember?.role === OrganizationRole.ADMIN;
    const isCreator = project.metadata?.creator?.id === (currentUserId || "no-member");
    const editable = canEdit && (isAdmin || isCreator);

    const [showDeleteProjectModal, setShowDeleteProjectModal] = useState<{
        project: PlainProject;
        state: ProjectState;
        creator?: PlainOrganizationMember;
    }>();

    const onEdit = useCallback(() => {
        navigate(`/projects/${project.id}`);
    }, [navigate, project.id]);

    const onDelete = useCallback(
        () => setShowDeleteProjectModal({ project, creator, state }),
        [creator, project, state],
    );

    const [active, setActive] = useState(false);
    const [menuOpen, setMenuOpen] = useState(false);
    const handleMouseLeave = useCallback(() => {
        if (!menuOpen) {
            setActive(false);
        }
    }, [menuOpen]);
    const handleMenuOpenChange = useCallback((newValue: boolean) => {
        if (!newValue) {
            setActive(false);
        }
        setMenuOpen(newValue);
    }, []);

    const { toast } = useToast();
    const onCopyUrl = useCallback(async () => {
        try {
            await navigator.clipboard.writeText(`${window.location.origin}/projects?project=${project.id}`);
            toast({
                title: "URL copied to clipboard",
            });
        } catch (error) {
            toast({
                title: "Failed to copy URL to your clipboard",
                description: formatError(error),
            });
        }
    }, [project, toast]);

    return (
        <div
            data-testid={"project-" + project.id}
            className={cn(
                "group flex flex-row gap-2 rounded-xl border border-solid border-border-light bg-surface-glass px-6 py-3 hover:shadow",
            )}
            data-active={active}
            onMouseEnter={() => setActive(true)}
            onMouseLeave={handleMouseLeave}
        >
            <div className="min-w-0 grow">
                <div className="truncate text-lg font-medium leading-6 text-content-primary">
                    {project.metadata?.name}
                </div>
                <div className="truncate text-base leading-5 text-content-secondary">
                    {projectContextUrl?.replace(/https?:\/\//, "")}
                </div>
            </div>
            <div
                className={cn(
                    "flex shrink-0 flex-col justify-center group-data-[active=true]:hidden",
                    forceHoverState ? "hidden" : "",
                )}
            >
                <div className="inline-flex justify-end">
                    {users?.map((u, idx) => {
                        return (
                            <img
                                key={u.userId}
                                referrerPolicy="no-referrer"
                                src={u.avatarUrl}
                                className={cn(
                                    "h-6 w-6 rounded-full border-[0.5px] border-black/50",
                                    idx > 0 && "ml-[-0.5rem]",
                                )}
                            />
                        );
                    })}
                    {totalUsedBy > 5 && (
                        <div
                            className={
                                "ml-[-0.5rem] h-6 w-6 rounded-full border-[0.5px] border-black/50 bg-gray-600 text-center text-sm leading-6 text-white"
                            }
                        >
                            +{Math.min(totalUsedBy, 9)}
                        </div>
                    )}
                </div>
            </div>
            <div
                className={cn(
                    "hidden items-center gap-1.5 group-data-[active=true]:flex",
                    forceHoverState ? "flex" : "",
                )}
            >
                <CreateEnvironmentButton
                    project={project}
                    variant="primary"
                    className="h-8 py-1"
                    onCreateSuccess={onCreateSuccess}
                    hideTooltip={true}
                >
                    Create Environment
                </CreateEnvironmentButton>
                {active && (
                    <DropdownActions
                        open={menuOpen}
                        onOpenChange={handleMenuOpenChange}
                        triggerTestId="project-actions-dropdown-trigger"
                    >
                        <DropdownMenuItem onClick={onCopyUrl}>Copy URL</DropdownMenuItem>
                        {editable && (
                            <>
                                <DropdownMenuSeparator className="bg-content-tertiary/20" />
                                <DropdownMenuItem onClick={onEdit} data-testid="project-actions-dropdown-edit">
                                    Edit
                                </DropdownMenuItem>
                                <DropdownMenuItem onClick={onDelete} className="text-content-red">
                                    Delete
                                </DropdownMenuItem>
                            </>
                        )}
                    </DropdownActions>
                )}
            </div>
            {showDeleteProjectModal && (
                <DeleteProjectModal
                    project={project}
                    state={showDeleteProjectModal.state}
                    creator={showDeleteProjectModal.creator}
                    currentMember={currentMember}
                    onClose={() => setShowDeleteProjectModal(undefined)}
                />
            )}
        </div>
    );
};

export const NoProjectCard: FC<{ onCreateEnvironment?: () => void }> = ({ onCreateEnvironment }) => {
    return (
        <div
            data-testid={"project-from-url"}
            className="group flex flex-row gap-2 rounded-xl border border-solid border-border-light px-6 py-3 text-xs hover:bg-surface-primary"
        >
            <div className="flex grow">
                <div className="min-w-0 flex-grow truncate">
                    <div className="text-base font-bold leading-6">Open a Repository URL</div>
                    <div className="text-sm leading-5 text-content-secondary">
                        Quickly create an environment from a repo URL
                    </div>
                </div>
            </div>
            <div className="flex items-center gap-1.5">
                <Button
                    id="create-environment"
                    type="button"
                    variant="primary"
                    onClick={(e) => {
                        e.stopPropagation();
                        onCreateEnvironment?.();
                    }}
                    className="h-8 py-1"
                    data-track-label="true"
                >
                    Create Environment
                </Button>
            </div>
        </div>
    );
};
