import type { Size } from "@/assets/icons/geist/Size";
import { type PropsWithClassName } from "@/components/podkit/lib/cn";
import type { FC } from "react";

export const IconRunner: FC<{ size: Size } & PropsWithClassName> = ({ size, className }) => {
    switch (size) {
        case "sm":
            return (
                <svg
                    className={className}
                    width="16"
                    height="16"
                    viewBox="0 0 16 16"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                >
                    <path
                        d="M5.33333 12.75H9.08333M12 12.75H14.0833M5.33333 9.41667H6.58333M9.5 9.41667H14.0833M4.5 4H14.9167C16.2974 4 17.4167 5.11929 17.4167 6.5V13.5833C17.4167 14.9641 16.2974 16.0833 14.9167 16.0833H4.5C3.11929 16.0833 2 14.9641 2 13.5833V6.5C2 5.11929 3.11929 4 4.5 4Z"
                        stroke="currentColor"
                        strokeWidth="1.25"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        fill="currentColor"
                    />
                </svg>
            );
        case "base":
            return (
                <svg
                    className={className}
                    width="20"
                    height="20"
                    viewBox="0 0 20 20"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                >
                    <path
                        d="M5.33333 12.75H9.08333M12 12.75H14.0833M5.33333 9.41667H6.58333M9.5 9.41667H14.0833M4.5 4H14.9167C16.2974 4 17.4167 5.11929 17.4167 6.5V13.5833C17.4167 14.9641 16.2974 16.0833 14.9167 16.0833H4.5C3.11929 16.0833 2 14.9641 2 13.5833V6.5C2 5.11929 3.11929 4 4.5 4Z"
                        stroke="currentColor"
                        strokeWidth="1.25"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                    />
                </svg>
            );
        case "lg":
            return (
                <svg
                    className={className}
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                >
                    <path
                        d="M5.33333 12.75H9.08333M12 12.75H14.0833M5.33333 9.41667H6.58333M9.5 9.41667H14.0833M4.5 4H14.9167C16.2974 4 17.4167 5.11929 17.4167 6.5V13.5833C17.4167 14.9641 16.2974 16.0833 14.9167 16.0833H4.5C3.11929 16.0833 2 14.9641 2 13.5833V6.5C2 5.11929 3.11929 4 4.5 4Z"
                        stroke="currentColor"
                        strokeWidth="1.25"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                    />
                </svg>
            );
    }
};
