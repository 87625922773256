import { IconChip } from "@/assets/icons/geist/IconChip";
import { IconPlusSquare } from "@/assets/icons/geist/IconPlusSquare";
import { ErrorMessage } from "@/components/ErrorMessage";
import { Button } from "@/components/flexkit/Button";
import { EnvironmentClassToggle } from "@/components/onboarding/EnvironmentClassToggle";
import { cn } from "@/components/podkit/lib/cn";
import { SkeletonBlock } from "@/components/podkit/loading/Skeleton";
import { Dialog, DialogContent } from "@/components/podkit/modal/Modal";
import { Text } from "@/components/podkit/typography/Text";
import { useMembership } from "@/hooks/use-membership";
import { useListRunnerEnvironmentClasses } from "@/queries/runner-configuration-queries";
import { EnvironmentClassAddModal } from "@/components/runners/details/EnvironmentClassAddModal";
import { EnvironmentClassEditModal } from "@/components/runners/details/EnvironmentClassEditModal";
import { RunnerDetailsSection, type SectionStatus } from "@/components/runners/details/RunnerDetailsSection";
import type { PlainMessage } from "@bufbuild/protobuf";
import { OrganizationRole } from "gitpod-next-api/gitpod/v1/organization_pb";
import { type EnvironmentClass } from "gitpod-next-api/gitpod/v1/runner_configuration_pb";
import type { RunnerConfigurationSchema_Field } from "gitpod-next-api/gitpod/v1/runner_configuration_pb";
import type { Runner } from "gitpod-next-api/gitpod/v1/runner_pb";
import { useCallback, useState, type FC, type PropsWithChildren } from "react";
import type { TrackLocation } from "@/hooks/use-segment";

type ModalState =
    | { type: "closed" }
    | { type: "add" }
    | {
          type: "edit";
          environmentClass: EnvironmentClass;
      };

export const EnvironmentClassesSection: FC<{
    runner: PlainMessage<Runner>;
    schema?: RunnerConfigurationSchema_Field[];
    hasStack: boolean;
    "data-track-location": (modalState: ModalState) => TrackLocation;
}> = ({ runner, schema, hasStack, "data-track-location": dataTrackLocation }) => {
    return (
        <EnvironmentClasses
            runner={runner}
            schema={schema}
            hasStack={hasStack}
            Container={RunnerDetailsSection}
            withDescription={true}
            data-track-location={dataTrackLocation}
        />
    );
};

export const EnvironmentClasses: FC<{
    runner: PlainMessage<Runner>;
    schema?: RunnerConfigurationSchema_Field[];
    hasStack: boolean;
    Container: React.ComponentType<{ status: SectionStatus } & PropsWithChildren>;
    withDescription: boolean;
    "data-track-location": (modalState: ModalState) => TrackLocation;
}> = ({ runner, schema, hasStack, Container, withDescription, "data-track-location": dataTrackLocation }) => {
    const {
        data: environmentClasses,
        isLoading: isLoadingEnvironmentClasses,
        error,
    } = useListRunnerEnvironmentClasses(runner.runnerId);
    const { membership, isPending: isLoadingMembership } = useMembership();
    const isLoading = isLoadingEnvironmentClasses || isLoadingMembership;

    const [modalState, setModalState] = useState<ModalState>({ type: "closed" });

    const onCloseModal = (open: boolean) => {
        if (!open) {
            setModalState({ type: "closed" });
        }
    };

    const onEdit = useCallback(
        (environmentClass: EnvironmentClass) => {
            setModalState({
                type: "edit",
                environmentClass,
            });
        },
        [setModalState],
    );

    const onAddEnvironmentClass = useCallback(() => {
        setModalState({ type: "add" });
    }, []);

    let status: SectionStatus = "loading";
    if (!isLoading) {
        if (!hasStack) {
            status = "locked";
        } else if (!schema?.length) {
            status = "initializing";
        } else {
            status = "optional";
        }
    }

    const ready = status !== "locked" && status !== "initializing";
    const readOnly = !ready || membership?.userRole !== OrganizationRole.ADMIN;

    return (
        <Container status={status}>
            <SkeletonBlock ready={status !== "loading"} className="mt-1 h-[100px]">
                <div className="flex flex-col gap-4">
                    {withDescription && (
                        <div className="flex flex-col items-start justify-between gap-4 md:flex-row">
                            <div className="flex flex-col gap-1">
                                <Text className={cn(!ready && "text-content-secondary", "text-lg font-bold")}>
                                    Customize your environment classes
                                </Text>
                                <Text className={cn(!ready && "text-content-secondary", "text-base")}>
                                    Customize your environment&#x2019;s classes to match your team&#x2019;s needs.
                                </Text>
                            </div>
                        </div>
                    )}
                    <ErrorMessage error={error} />
                    <EnvironmentClassesList
                        ready={ready}
                        environmentClasses={environmentClasses || []}
                        onEdit={onEdit}
                        readOnly={readOnly}
                        onAddEnvironmentClass={onAddEnvironmentClass}
                    />
                </div>
            </SkeletonBlock>
            {schema && modalState.type !== "closed" && (
                <Dialog open onOpenChange={onCloseModal}>
                    <DialogContent className="max-w-xl" data-track-location={dataTrackLocation(modalState)}>
                        {modalState.type == "edit" && (
                            <EnvironmentClassEditModal
                                environmentClass={modalState.environmentClass}
                                schema={schema}
                                onClose={() => onCloseModal(false)}
                            />
                        )}
                        {modalState.type == "add" && (
                            <EnvironmentClassAddModal
                                runnerId={runner.runnerId}
                                schema={schema}
                                onClose={() => onCloseModal(false)}
                            />
                        )}
                    </DialogContent>
                </Dialog>
            )}
        </Container>
    );
};

const EnvironmentClassesList: FC<{
    ready: boolean;
    environmentClasses: EnvironmentClass[];
    readOnly: boolean;
    onEdit: (environmentClass: EnvironmentClass) => void;
    onAddEnvironmentClass: () => void;
}> = ({ ready, environmentClasses, readOnly, onEdit, onAddEnvironmentClass }) => {
    return (
        <div className="flex w-full flex-col gap-2">
            {environmentClasses.map((environmentClass) => (
                <EnvironmentClassRow
                    key={environmentClass.id}
                    environmentClass={environmentClass}
                    onEdit={onEdit}
                    readOnly={readOnly}
                />
            ))}
            {ready && (
                <Button
                    onClick={onAddEnvironmentClass}
                    variant="link"
                    size="lg"
                    disabled={readOnly}
                    data-testid="add-environment-class"
                    LeadingIcon={IconPlusSquare}
                    className="w-full rounded-xl border-[1px] border-dashed px-3 py-2 hover:bg-surface-02"
                    data-track-label="true"
                >
                    <Text className="flex h-9 w-full items-center">Add a new environment class</Text>
                </Button>
            )}
        </div>
    );
};

const EnvironmentClassRow: FC<{
    environmentClass: EnvironmentClass;
    readOnly: boolean;
    onEdit: (environmentClass: EnvironmentClass) => void;
}> = ({ environmentClass, readOnly, onEdit }) => {
    return (
        <div className="flex items-center justify-between gap-4 rounded-xl border-0.5 border-border-base px-3 py-2">
            <div className="flex items-center gap-2">
                <IconChip size="lg" />
                <div className="flex flex-col">
                    <Text className={cn("text-base font-bold", !environmentClass.enabled && "text-content-secondary")}>
                        <span>
                            {environmentClass.displayName} <span>{!environmentClass.enabled ? "(Disabled)" : ""}</span>
                        </span>
                    </Text>
                    <Text className={cn("text-sm", !environmentClass.enabled && "text-content-secondary")}>
                        {environmentClass.description}
                    </Text>
                </div>
            </div>
            <div className="flex items-center gap-2">
                {environmentClass.enabled && (
                    <Button
                        variant="secondary"
                        onClick={() => onEdit(environmentClass)}
                        disabled={readOnly}
                        data-track-label="true"
                    >
                        Edit
                    </Button>
                )}
                <EnvironmentClassToggle environmentClass={environmentClass} />
            </div>
        </div>
    );
};
