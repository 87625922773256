import Breadcrumbs from "@/components/Breadcrumb";
import { Flags } from "@/feature-flags";
import { useDocumentTitle } from "@/hooks/use-document-title";
import { useListProjectStates, useProject } from "@/queries/project-queries";
import { ProjectDetailsForm } from "@/routes/projects/details/ProjectDetailsForm";
import { ProjectSecrets } from "@/routes/projects/details/ProjectSecrets";
import { useFlag } from "@/hooks/use-flag";
import { useCallback, type FC } from "react";
import { useParams } from "react-router-dom";
import { Heading2 } from "@/components/podkit/typography/Headings";
import { Text } from "@/components/podkit/typography/Text";
import { SadBox } from "@/assets/icons/SadBax";
import { Button } from "@/components/flexkit/Button";
import { IconRefresh } from "@/assets/icons/geist/IconRefresh";
import { SkeletonBlock, SkeletonText } from "@/components/podkit/loading/Skeleton";

export const ProjectDetailsPage: FC = () => {
    useDocumentTitle("Project - Details");

    const { projectId } = useParams();
    const { value: isSecretsEnabled } = useFlag(Flags.SecretsEnabled, false);
    const { data: project, error: getProjectError, isPending: isLoadingProject } = useProject(projectId);
    const { data: projectsStates, isLoading: isLoadingProjectStates } = useListProjectStates(
        project?.id ? [project.id] : [],
    );
    const projectStatesError = !isLoadingProjectStates && projectsStates?.length === 0;

    let content: React.ReactNode | null = null;
    if (isLoadingProject || isLoadingProjectStates) {
        content = <Loading />;
    } else if (getProjectError || projectStatesError || !projectId) {
        content = <Trouble />;
    } else {
        content = (
            <>
                {project && projectsStates && <ProjectDetailsForm project={project} state={projectsStates[0]} />}
                {isSecretsEnabled && <ProjectSecrets projectId={projectId} />}
            </>
        );
    }

    return (
        <div className="flex flex-col gap-6" data-testid="project-details-page" key={projectId}>
            <Breadcrumbs
                label={
                    <SkeletonText
                        size="lg"
                        ready={Boolean(project)}
                        failed={Boolean(getProjectError)}
                        failedFallback={projectId}
                        className="w-[150px]"
                    >
                        {project?.metadata?.name}
                    </SkeletonText>
                }
            />
            {content}
        </div>
    );
};

const Loading: FC = () => {
    return (
        <div className="flex max-w-[456px] flex-col gap-5">
            <div className="flex flex-col gap-1">
                <SkeletonText size="lg" ready={false} className="w-[120px]" />
                <SkeletonBlock ready={false} className="h-9 w-full" />
            </div>
            <div className="flex flex-col gap-1">
                <SkeletonText size="lg" ready={false} className="w-[120px]" />
                <SkeletonBlock ready={false} className="h-9 w-full" />
            </div>
            <div className="flex flex-col gap-1">
                <SkeletonText size="lg" ready={false} className="w-[135px]" />
                <SkeletonBlock ready={false} className="h-14 w-full" />
            </div>
            <div className="flex flex-col gap-1">
                <SkeletonText size="lg" ready={false} className="w-[160px]" />
                <SkeletonBlock ready={false} className="h-9 w-full" />
            </div>
            <div className="flex flex-col gap-1">
                <SkeletonText size="lg" ready={false} className="w-[150px]" />
                <SkeletonBlock ready={false} className="h-9 w-full" />
            </div>
            <div className="flex flex-col gap-1">
                <SkeletonText size="lg" ready={false} className="w-14" />
                <SkeletonBlock ready={false} className="h-20 w-56" />
            </div>
            <SkeletonBlock ready={false} className="h-8 w-32" />
        </div>
    );
};

const Trouble: FC = () => {
    const onReload = useCallback(() => {
        window.location.reload();
    }, []);

    return (
        <div className="flex flex-col items-center gap-5 p-12" data-testid="error-failed-to-load-project-data">
            <SadBox />
            <div className="flex flex-col items-center gap-1">
                <Heading2 className="text-xl font-bold text-content-primary">
                    We&apos;re having trouble loading the project.
                </Heading2>
                <Text className="text-lg text-content-secondary">
                    You can try refreshing the page. If the problem persists, please contact support.
                </Text>
            </div>
            <Button variant="secondary" LeadingIcon={IconRefresh} data-track-label="true" onClick={onReload}>
                Refresh Page
            </Button>
        </div>
    );
};
