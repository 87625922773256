import { useCallback, useState, type FC } from "react";
import {
    Dialog,
    DialogBody,
    DialogClose,
    DialogContent,
    DialogFooter,
    DialogHeader,
    DialogTitle,
} from "@/components/podkit/modal/Modal";

import { Button } from "@/components/flexkit/Button";
import { CreateEnvironmentDetailsLayout } from "@/routes/environments/create/CreateEnvironment";
import { TrackLocations } from "@/hooks/use-segment";

type ModalState = "intro" | "create-environment";

export const CreateProjectModal: FC<{ onClose: () => void }> = ({ onClose }) => {
    const onOpenChange = (open: boolean) => {
        if (!open) {
            onClose();
        }
    };

    const [state, setState] = useState<ModalState>("intro");

    let classes: string;
    switch (state) {
        case "intro":
            classes = "w-[500px] max-w-[500px]";
            break;
        case "create-environment":
            classes = "max-w-128 w-128";
            break;
    }

    return (
        <Dialog open onOpenChange={onOpenChange}>
            <DialogContent className={classes} data-track-location={TrackLocations.CreateProjectModal}>
                {state == "intro" && <CreateProjectIntro onClose={onClose} setState={setState} />}
                {state == "create-environment" && <CreateEnvironmentDetailsLayout onClose={onClose} />}
            </DialogContent>
        </Dialog>
    );
};

const CreateProjectIntro: FC<{ onClose: () => void; setState: (state: ModalState) => void }> = ({
    onClose,
    setState,
}) => {
    const handleCreateProject = useCallback(() => {
        setState("create-environment");
    }, [setState]);

    return (
        <>
            <DialogHeader>
                <DialogTitle className="text-xl">Create a project</DialogTitle>
            </DialogHeader>

            <DialogBody>
                <div className="flex p-1 text-lg">
                    Start an environment to create and configure your project. Projects are pre-configured templates
                    that provide one-click environments.
                </div>
            </DialogBody>

            <DialogFooter>
                <DialogClose asChild>
                    <Button type="button" variant="secondary" onClick={onClose} data-track-label="true">
                        Cancel
                    </Button>
                </DialogClose>
                <Button
                    type="button"
                    variant="primary"
                    autoFocus={true}
                    onClick={handleCreateProject}
                    data-track-label="true"
                >
                    Create Environment
                </Button>
            </DialogFooter>
        </>
    );
};
