import { Button } from "@/components/flexkit/Button";
import {
    Dialog,
    DialogBody,
    DialogContent,
    DialogDescription,
    DialogFooter,
    DialogHeader,
    DialogTitle,
} from "@/components/podkit/modal/Modal";
import { Text } from "@/components/podkit/typography/Text";
import { useLocalRunner } from "@/hooks/use-local-runner";
import { useOrganization } from "@/queries/organization-queries";
import { useListEnvironments } from "@/queries/environment-queries";
import { EnvironmentPhase } from "gitpod-next-api/gitpod/v1/environment_pb";
import React, { useCallback, useMemo, type FC } from "react";
import type { TrackLocation } from "@/hooks/use-segment";

type SwithLocalRunnerModalProps = {
    onClose: () => void;
    onConfirm: () => void;
    "data-track-location": TrackLocation;
};

export const SwitchOrgModal: FC<SwithLocalRunnerModalProps> = ({
    onClose,
    onConfirm,
    "data-track-location": dataTrackLocation,
}) => {
    const { data: org } = useOrganization();
    const listEnvironments = useListEnvironments();
    const localRunner = useLocalRunner();
    const isLoading =
        listEnvironments.isLoading ||
        listEnvironments.isPending ||
        listEnvironments.isFetching ||
        listEnvironments.isRefetching ||
        localRunner.loading;

    const localActiveEnvs = useMemo(() => {
        if (isLoading) {
            return undefined;
        }
        // we only check first 100 for now
        // TODO(ak) we need new API to get exact count, i.e. use filters by runner id and phases
        // local runner anyway won't be able to have 100 environments
        return listEnvironments.data?.environments.filter(
            (w) =>
                w.metadata?.runnerId === localRunner.status?.currentRunnerId &&
                (w.status?.phase || EnvironmentPhase.UNSPECIFIED) < EnvironmentPhase.STOPPING,
        ).length;
    }, [listEnvironments.data, isLoading, localRunner.status]);

    // ensure that we don't call confirm several times becuse of react re-render
    const confirmed = React.useRef(false);
    const confirm = useCallback(() => {
        if (confirmed.current) {
            return;
        }
        confirmed.current = true;
        onConfirm();
    }, [onConfirm]);

    if (!org || localActiveEnvs === undefined) {
        return null;
    }
    if (!(window.ipcRenderer && localActiveEnvs > 0)) {
        confirm();
        return null;
    }
    const onOpenChange = (open: boolean) => {
        if (!open) {
            onClose();
        }
    };
    return (
        <Dialog open onOpenChange={onOpenChange}>
            <DialogContent className="max-w-[400px]" data-track-location={dataTrackLocation}>
                <DialogHeader>
                    <DialogTitle>Switching organization</DialogTitle>
                    <DialogDescription />
                </DialogHeader>
                <DialogBody>
                    <Text className="mb-4">
                        <b>
                            Stop <span>{localActiveEnvs}</span> local{" "}
                            <span>{localActiveEnvs === 1 ? "environment" : "environments"}</span> and switch?
                        </b>
                    </Text>
                    <Text>
                        Any running local environments belonging to <b>{org.name}</b> will be stopped
                    </Text>
                </DialogBody>
                <DialogFooter>
                    <Button type="button" variant="secondary" onClick={onClose} data-track-label="true">
                        Cancel
                    </Button>
                    <Button type="submit" autoFocus={true} variant="primary" onClick={confirm} data-track-label="true">
                        Yes, Continue
                    </Button>
                </DialogFooter>
            </DialogContent>
        </Dialog>
    );
};
