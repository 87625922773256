import { type FC, useCallback } from "react";
import {
    Dialog,
    DialogBody,
    DialogClose,
    DialogContent,
    DialogDescription,
    DialogFooter,
    DialogHeader,
    DialogTitle,
} from "@/components/podkit/modal/Modal";
import { Text } from "@/components/podkit/typography/Text";
import { useToast } from "@/components/podkit/toasts/use-toast";
import { formatError } from "@/utils/errors";
import { useDeletePersonalAccessToken, type PlainPersonalAccessToken } from "@/queries/personal-access-tokens-queries";
import { PersonalAccessTokenCard } from "@/routes/settings/user/personal-access-tokens/PersonalAccessTokenCard";
import { Button } from "@/components/flexkit/Button";
import { TrackLocations } from "@/hooks/use-segment";

type DeletePersonalAccessTokenModalProps = {
    pat: PlainPersonalAccessToken;
    onClose: () => void;
};

export const DeletePersonalAccessTokenModal: FC<DeletePersonalAccessTokenModalProps> = ({
    pat,
    onClose,
}: DeletePersonalAccessTokenModalProps) => {
    const { toast } = useToast();

    const deletePersonalAccessToken = useDeletePersonalAccessToken();

    const onOpenChange = (open: boolean) => {
        if (!open) {
            onClose();
        }
    };

    const handleDeletePersonalAccessToken = useCallback(() => {
        deletePersonalAccessToken.mutate(
            {
                personalAccessTokenId: pat.id,
            },
            {
                onSuccess: () => {
                    toast({ title: "Personal Access Token deleted" });
                    onClose();
                },
                onError: (err) => {
                    toast({ title: "Failed to delete personal access token", description: formatError(err) });
                },
            },
        );
    }, [deletePersonalAccessToken, onClose, pat, toast]);

    return (
        <Dialog open onOpenChange={onOpenChange}>
            <DialogContent className="max-w-xl" data-track-location={TrackLocations.DeletePersonalAccessTokenModal}>
                <DialogHeader>
                    <DialogTitle>Delete Personal Access Token</DialogTitle>
                    <DialogDescription />
                </DialogHeader>

                <DialogBody>
                    <div className="flex flex-col space-y-2">
                        <Text className="text-base">
                            Once this token is removed, applications or scripts will lose access to the Gitpod API. This
                            action cannot be undone.
                        </Text>
                        <PersonalAccessTokenCard pat={pat} />
                    </div>
                </DialogBody>

                <DialogFooter className="sm:justify-end">
                    <DialogClose asChild>
                        <Button type="button" variant="secondary" onClick={onClose} data-track-label="true">
                            Cancel
                        </Button>
                    </DialogClose>
                    <Button
                        autoFocus={true}
                        variant="destructive"
                        loading={deletePersonalAccessToken.isPending}
                        onClick={handleDeletePersonalAccessToken}
                        data-track-label="true"
                    >
                        Yes, Delete
                    </Button>
                </DialogFooter>
            </DialogContent>
        </Dialog>
    );
};
