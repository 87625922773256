import gitpodLogo from "@/assets/gitpod-logo.svg";
import { useLocalRunner } from "@/hooks/use-local-runner";
import {
    LocalRunnerStatus,
    type LocalRunnerInstallationState,
    type LocalRunnerInstallationStep,
} from "frontend-shared/local-runner";
import { Loader2 as ProgressIcon } from "lucide-react";
import { useCallback, useEffect, useRef, useState, type FC, type PropsWithChildren } from "react";

import { IconCheckFill } from "@/assets/icons/geist/IconCheckFill";
import { IconDot } from "@/assets/icons/geist/IconDot";
import { IconSpinner } from "@/assets/icons/geist/IconSpinner";
import { Button } from "@/components/flexkit/Button";
import { Alert } from "@/components/podkit/alert/Alert";
import { LoadingState } from "@/components/podkit/loading/LoadingState";
import { Heading1, Heading2 } from "@/components/podkit/typography/Headings";
import { useDocumentTitle } from "@/hooks/use-document-title";
import { appMainService, runnerMainService } from "@/ipc";
import { Navigate } from "react-router-dom";
import { IconWarning } from "@/assets/icons/geist/IconWarning";
import { useBootIntercom } from "@/hooks/use-intercom";

const LocalRunnerLayout = ({ children }: PropsWithChildren) => {
    return (
        <div className="m-8 mt-20 flex flex-col items-center">
            <img src={gitpodLogo} className="mb-8" alt="" width="45" height="45" />
            {children}
        </div>
    );
};

export const LocalRunner: FC = () => {
    useBootIntercom();

    const localRunner = useLocalRunner();
    useDocumentTitle("Local Runner");

    if (localRunner.loading) {
        return <LoadingState />;
    }
    if (!LocalRunnerStatus.needsRunnerScreen(localRunner.status)) {
        return <Navigate to="/" />;
    }
    if (localRunner.status?.phase === "failed") {
        return <FailedLocalRunner status={localRunner.status} />;
    }
    if (
        localRunner.status?.phase === "installing" &&
        localRunner.status?.installation?.needsElevatedPermissions &&
        Object.entries(localRunner.status?.installation?.steps || {}).length === 0
    ) {
        return <LocalRunnerAuthorizationRequired status={localRunner.status} />;
    }

    if (localRunner.status?.phase === "installing") {
        return <InstallLocalRunner />;
    }
    return <div>{localRunner.status?.phase}</div>;
};

type LocalRunnerProps = {
    status: LocalRunnerStatus;
};
export function FailedLocalRunner({ status }: LocalRunnerProps) {
    const onCollectLogsAndDiagnostics = useCallback(() => appMainService?.collectLogsAndDiagnostics(), []);
    const onRetry = useCallback(() => runnerMainService?.run({ elevatePermissions: true }), []);

    return (
        <LocalRunnerLayout>
            <div className="flex w-[500px] flex-col gap-4 p-8 px-12">
                <Heading1 className="text-wrap text-center">
                    {status.failure?.message || "Oops, something went wrong"}
                </Heading1>
                <p className="text-center text-lg">
                    Please try again, or click <em>Collect Diagnostics</em> to download the logs and report the issue.
                </p>
                <div className="mb-2 mt-2 inline-flex w-full justify-stretch gap-4">
                    <Button type="button" className="grow" variant="primary" onClick={onRetry} data-track-label="true">
                        Retry
                    </Button>
                    <Button
                        type="button"
                        className="grow"
                        variant="secondary"
                        onClick={onCollectLogsAndDiagnostics}
                        data-track-label="true"
                    >
                        Collect Diagnostics
                    </Button>
                </div>
            </div>
        </LocalRunnerLayout>
    );
}

export function InstallLocalRunner() {
    const localRunner = useLocalRunner();

    const onQuit = useCallback(() => appMainService?.quit(), []);
    const onRetry = useCallback(() => runnerMainService?.run({ elevatePermissions: true }), []);

    // We want do delay the "done" state of the individual steps, to create a more pleasing impression during installation
    const timer = useRef<NodeJS.Timeout | undefined>(undefined);
    const [stepCountCap, setStepCountCap] = useState(0);
    const [output, setOutput] = useState<LocalRunnerInstallationState>({ steps: {} });

    useEffect(() => {
        const filterStepsDone = (newState: LocalRunnerInstallationState, cap: number) => {
            const steps = Object.entries(newState.steps);
            const filteredSteps: Record<number, LocalRunnerInstallationStep> = {};
            for (const [ok, os] of steps) {
                const k = Number.parseInt(ok);
                const s = { ...os };
                filteredSteps[k] = s;
                if (k + 1 > cap) {
                    s.done = false;
                }
                if (k > cap) {
                    s.start = false;
                }
            }
            setOutput({
                ...newState,
                steps: filteredSteps,
            });
        };

        const newState = localRunner.status?.installation;
        if (!newState) {
            return;
        }

        const steps = Object.entries(newState.steps);
        const someError =
            newState.exitCode !== undefined || !!localRunner.status?.failure || steps.some(([_, s]) => !!s.error);
        const doneSteps = steps.filter(([_, s]) => s.done).length;
        const allDone = steps.length === doneSteps;
        if (someError || doneSteps === stepCountCap) {
            // Something is odd, or we don't want to slow down: set immediately
            setOutput(newState);
            if (timer.current) {
                clearTimeout(timer.current);
            }
            return;
        }
        filterStepsDone(newState, stepCountCap);

        if (doneSteps > stepCountCap) {
            if (timer.current) {
                return;
            }
            const waitTime = allDone ? 500 : 2000; // don't let people wait forever if we're already done
            timer.current = setTimeout(() => {
                timer.current = undefined;
                setStepCountCap(stepCountCap + 1);
            }, waitTime);
        }
    }, [localRunner.status?.installation, localRunner.status?.failure, stepCountCap, setStepCountCap, setOutput]);

    const steps = Object.entries(output.steps).map(([, step]) => step);
    const isError = typeof output.exitCode === "number" && output.exitCode !== 0;

    return <InstallLocalRunnerView steps={steps} installFailed={isError} onQuit={onQuit} onRetry={onRetry} />;
}

type InstallLocalRunnerViewProps = {
    steps: LocalRunnerInstallationStep[];
    installFailed: boolean;
    onQuit: () => void;
    onRetry: () => void;
};

export const InstallLocalRunnerView: FC<InstallLocalRunnerViewProps> = ({ steps, installFailed, onQuit, onRetry }) => {
    const errorMessage =
        steps
            .map((step) => step.error)
            .reverse()
            .find((e) => e) || "Unknown errors occurred.";
    return (
        <LocalRunnerLayout>
            <div className="flex w-[500px] flex-col items-center px-12">
                <Heading1 className="pb-3">Just a second ...</Heading1>

                <div className="pl-6">
                    {steps.map((step) => (
                        <div key={step.name} className="flex w-full flex-row items-center justify-start">
                            <div className="flex size-8 items-center justify-center">{getStatusIcon(step)}</div>
                            <div className="ml-1">{step.name}</div>
                        </div>
                    ))}
                </div>

                {installFailed && (
                    <Alert className="mt-4 bg-surface-02">
                        <Heading2 className="text-center">Oops, something went wrong</Heading2>
                        <p className="break-words p-4 font-mono text-xs">{errorMessage}</p>
                        <div className="mt-2 inline-flex w-full justify-center gap-2">
                            <Button
                                type="button"
                                variant="secondary"
                                className=""
                                onClick={onQuit}
                                data-track-label="true"
                            >
                                Quit
                            </Button>
                            <Button
                                type="button"
                                variant="secondary"
                                className=""
                                onClick={onRetry}
                                data-track-label="true"
                            >
                                Try Again
                            </Button>
                        </div>
                    </Alert>
                )}
            </div>
        </LocalRunnerLayout>
    );
};

function getStatusIcon(step: { start: boolean; done: boolean; error?: string }) {
    if (step.error) {
        return <IconWarning size="lg" className="text-content-negative" />;
    }
    if (step.done) {
        return <IconCheckFill size="lg" className="text-content-green" />;
    }
    if (step.start) {
        return <IconSpinner size="lg" className="animate-spin text-content-yield" />;
    }

    return <IconDot size="lg" className="text-content-secondary" />;
}

export function LocalRunnerAuthorizationRequired({ status }: LocalRunnerProps) {
    const onAuthorize = useCallback(() => runnerMainService?.run({ elevatePermissions: true }), []);

    return (
        <LocalRunnerLayout>
            <div className="flex w-[500px] flex-col items-center p-8 px-12">
                <Heading1 className="pb-3" id="permission-required">
                    Welcome to Gitpod
                </Heading1>
                <p className="text-center text-xl text-content-secondary">
                    Gitpod requires system access to configure local Linux virtualization.
                </p>

                <div className="mt-6 w-full text-center">
                    <Button
                        id="authorize"
                        className="w-full"
                        variant={"primary"}
                        size={"lg"}
                        onClick={onAuthorize}
                        disabled={Object.entries(status?.installation?.steps || {}).length > 0}
                        data-track-label="true"
                    >
                        Authorize Gitpod
                    </Button>
                </div>
            </div>
        </LocalRunnerLayout>
    );
}

export const QuitApp: FC = () => {
    const params = new URLSearchParams(window.location.search);
    const appName = params.get("app") || "Gitpod";
    const message = params.get("message") || "Stopping local environments...";
    return (
        <LocalRunnerLayout>
            <Heading2>Closing {appName}</Heading2>
            <div className="flex items-center py-3">
                <ProgressIcon className="mr-1 size-4 animate-spin stroke-content-yield" />
                {message}
            </div>
        </LocalRunnerLayout>
    );
};
