import { useTheme } from "@/hooks/use-theme";
import { Computer, Moon, Sun } from "lucide-react";
import {
    Select,
    SelectContent,
    SelectGroup,
    SelectItem,
    SelectTrigger,
    SelectValue,
} from "@/components/podkit/select/Select";
import type { FC } from "react";
import { themes, type Theme } from "@/contexts/ThemeProvider";

export function ThemeToggle() {
    const { theme, setTheme } = useTheme();
    return (
        <Select name="theme" value={theme} onValueChange={setTheme}>
            <SelectTrigger loading={false} className="max-w-fit">
                <SelectValue>
                    <ThemeValue theme={theme} />
                </SelectValue>
                <SelectContent>
                    <SelectGroup>
                        {themes.map((theme) => (
                            <SelectItem key={theme} value={theme}>
                                <ThemeValue theme={theme} />
                            </SelectItem>
                        ))}
                    </SelectGroup>
                </SelectContent>
            </SelectTrigger>
        </Select>
    );
}

const ThemeValue: FC<{ theme: Theme }> = ({ theme }) => {
    return (
        <div className="flex items-center gap-2">
            <ThemeIcon theme={theme} />
            <span className="capitalize">{theme}</span>
        </div>
    );
};

const ThemeIcon: FC<{ theme: Theme }> = ({ theme }) => {
    switch (theme) {
        case "light":
            return <Sun className="h-[1.2rem] w-[1.2rem]" />;
        case "dark":
            return <Moon className="h-[1.2rem] w-[1.2rem]" />;
        case "system":
            return <Computer className="h-[1.2rem] w-[1.2rem]" />;
    }
};
