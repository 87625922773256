import { IconExternalLink } from "@/assets/icons/geist/IconExternalLink";
import { ErrorMessage } from "@/components/ErrorMessage";
import { Button } from "@/components/flexkit/Button";
import { cn, type PropsWithClassName } from "@/components/podkit/lib/cn";
import { SkeletonBlock, SkeletonText } from "@/components/podkit/loading/Skeleton";
import { useToast } from "@/components/podkit/toasts/use-toast";
import { ExternalLink } from "@/components/podkit/typography/Link";
import { Text } from "@/components/podkit/typography/Text";
import { useCreateRunnerAccessToken } from "@/queries/runner-queries";
import { RunnerAdditionalFieldsKeys } from "@/components/runners/details/runner-configuration-keys";
import { RunnerDetailsSection, type SectionStatus } from "@/components/runners/details/RunnerDetailsSection";
import { RunnerPhaseTag } from "@/components/runners/RunnerCard";
import { createRunnerSetupText, createRunnerSetupURL } from "@/components/runners/RunnerSetupURL";
import { formatError } from "@/utils/errors";
import type { PlainMessage } from "@bufbuild/protobuf";
import { RunnerKind, type Runner } from "gitpod-next-api/gitpod/v1/runner_pb";
import { useCallback, type FC, type ReactNode } from "react";

export const CloudFormationStack: FC<{ runner: PlainMessage<Runner> | undefined; hasStack: boolean }> = ({
    runner,
    hasStack,
}) => {
    if (runner && runner.kind !== RunnerKind.REMOTE) {
        return null;
    }

    let status: SectionStatus = "loading";
    if (runner) {
        status = hasStack ? "complete" : "required";
    }

    return (
        <RunnerDetailsSection status={status}>
            <div className="flex flex-col gap-4">
                <div className="flex flex-col gap-1">
                    <SkeletonText ready={status !== "loading"} size="lg" className="w-48">
                        <Text className="text-lg font-bold">AWS CloudFormation</Text>
                    </SkeletonText>
                    {!hasStack && (
                        <Text className="text-base">
                            We&#x2019;ll help you setup a new runner on your AWS EC2 infrastructure. The link below will
                            take you to AWS CloudFormation with a set of pre-populated values to quickly get everything
                            working.{" "}
                            <ExternalLink
                                className="font-bold"
                                href="https://www.gitpod.io/docs/flex/runners/aws/setup-aws-runners#cloudformation-template"
                            >
                                Read the docs.
                            </ExternalLink>
                        </Text>
                    )}
                </div>
                <CloudFormationStackContent runner={runner} hasStack={hasStack} />
            </div>
        </RunnerDetailsSection>
    );
};

export const CloudFormationStackContent: FC<
    { runner: PlainMessage<Runner> | undefined; hasStack: boolean } & PropsWithClassName
> = ({ runner, hasStack, className }) => {
    const { toast } = useToast();
    const createRunnerToken = useCreateRunnerAccessToken();

    const onCopyDetails = useCallback(async () => {
        if (!runner) {
            return;
        }
        try {
            const region = runner.spec?.configuration?.region;
            const { accessToken } = await createRunnerToken.mutateAsync(runner.runnerId);
            await navigator.clipboard.writeText(createRunnerSetupText(runner, accessToken, region || ""));
            toast({
                title: "Message copied to clipboard",
            });
        } catch (error) {
            toast({
                title: "Failed to copy details to your clipboard",
                description: formatError(error),
            });
        }
    }, [toast, createRunnerToken, runner]);
    return (
        <SkeletonBlock ready={Boolean(runner)} className="h-[150px]">
            {runner && (
                <div className="flex flex-col gap-4">
                    <div className={cn("rounded-lg bg-surface-tertiary p-4", className)}>
                        {hasStack ? (
                            <CloudFormationStackDetails runner={runner} />
                        ) : (
                            <CloudFormationStackCreate runner={runner} />
                        )}
                    </div>
                    {!hasStack && (
                        <div className="flex flex-col items-center gap-1 lg:flex-row">
                            <Text className="text-base">Does someone else manage your AWS account?</Text>
                            <Button
                                variant="link"
                                onClick={onCopyDetails}
                                className="inline-block h-auto p-0 text-content-orange disabled:bg-transparent"
                                disabled={createRunnerToken.isPending}
                                data-track-label="true"
                            >
                                Copy the details and share them.
                            </Button>
                        </div>
                    )}
                </div>
            )}
        </SkeletonBlock>
    );
};

const CloudFormationStackCreate: FC<{ runner: PlainMessage<Runner> }> = ({ runner }) => {
    const createRunnerToken = useCreateRunnerAccessToken();
    const { toast } = useToast();
    const onClick = useCallback(async () => {
        const region = runner.spec?.configuration?.region;
        const { accessToken } = await createRunnerToken.mutateAsync(runner.runnerId);
        const setupURL = createRunnerSetupURL(runner, accessToken, region || "");
        window.open(setupURL, "awsWindow", "popup");
        toast({
            title: "Opened AWS CloudFormation Stack URL",
            description: (
                <Text>
                    If the window was blocked by your browser, you can use{" "}
                    <ExternalLink href={setupURL}>this link</ExternalLink> instead.
                </Text>
            ),
        });
    }, [runner, createRunnerToken, toast]);
    return (
        <div className="flex flex-col items-center gap-4 text-center">
            <div className="flex flex-col">
                <Text className="text-lg font-bold">
                    Once you&apos;ve executed the CloudFormation template in AWS, please return to this page.
                </Text>
                <Text className="text-base text-content-secondary">
                    Gitpod will register your runner after the stack has been created. This will take 2-3 minutes.
                </Text>
            </div>
            {runner && <RunnerPhaseTag runner={runner} />}
            <Button
                onClick={onClick}
                variant={"primary"}
                LeadingIcon={IconExternalLink}
                loading={createRunnerToken.isPending}
                disabled={createRunnerToken.isPending || !runner.spec?.configuration?.region}
                data-track-label="true"
            >
                Open AWS CloudFormation
            </Button>
            <ErrorMessage error={createRunnerToken.error} />
        </div>
    );
};

const CloudFormationStackDetails: FC<{ runner: PlainMessage<Runner> }> = ({ runner }) => {
    const dict = Object.fromEntries(runner.status?.additionalInfo.map((info) => [info.key, info.value]) || []);

    const accountID = dict[RunnerAdditionalFieldsKeys.AWSAccountID] || "";
    const url = dict[RunnerAdditionalFieldsKeys.StackURL] || "";
    const name = dict[RunnerAdditionalFieldsKeys.StackName] || "";
    const region = dict[RunnerAdditionalFieldsKeys.Region] || "";

    return (
        <div className="flex flex-col gap-2 overflow-x-hidden">
            {runner && (
                <Info label="Status">
                    <RunnerPhaseTag runner={runner} />
                </Info>
            )}
            {accountID && <Info label="AWS Account ID">{accountID}</Info>}
            {name && <Info label="Stack name">{name}</Info>}
            {url && (
                <Info label="Stack URL">
                    <ExternalLink href={url} className="block truncate text-content-orange">
                        {url}
                    </ExternalLink>
                </Info>
            )}
            <Info label="Runner name">{runner.name}</Info>
            {region && <Info label="Runner region">{region}</Info>}
        </div>
    );
};

const Info: FC<{ label: string; children: ReactNode }> = ({ label, children }) => (
    <div className="flex flex-col gap-1 md:flex-row md:gap-2">
        <Text className="min-w-max text-base">{label}: </Text>
        <Text className="shrink truncate text-base font-bold">{children}</Text>
    </div>
);
