import { Input } from "@/components/podkit/forms/Input";
import { DialogBody, DialogHeader, DialogTitle } from "@/components/podkit/modal/Modal";
import { ProjectsList } from "@/routes/projects/ProjectsList";
import { DialogDescription } from "@radix-ui/react-dialog";
import { Search } from "lucide-react";
import { useState, type FC } from "react";

export const ProjectSelectorLayout: FC<{ onClose: () => void; onCreateFromUrl: () => void }> = ({
    onClose,
    onCreateFromUrl,
}) => {
    const [searchValue, setSearchValue] = useState<string>();

    return (
        <>
            <DialogHeader>
                <DialogTitle>Create an environment</DialogTitle>
                <DialogDescription />
            </DialogHeader>

            <DialogBody className="flex max-w-full grow flex-col gap-4 overflow-hidden">
                <label className="relative flex">
                    <Search className="absolute left-[12px] top-[11px] z-10 text-content-tertiary" size={14} />
                    <Input
                        placeholder="Search projects"
                        className="w-full max-w-none pl-8"
                        value={searchValue}
                        onChange={(e) => setSearchValue(e.target.value.trimStart().replace(/\s+/g, " "))}
                    />
                </label>
                <div className="grow overflow-y-auto">
                    <ProjectsList
                        canEdit={false}
                        showNoProjectCard={true}
                        onCreateSuccess={onClose}
                        onCreateFromUrl={onCreateFromUrl}
                        search={searchValue}
                    />
                </div>
            </DialogBody>
        </>
    );
};
