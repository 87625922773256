import { useMemo, useState, type FC } from "react";
import { Text } from "@/components/podkit/typography/Text";
import { type PlainSecret } from "@/queries/secret-queries";
import { Table, TableBody, TableCell, TableHead, TableHeader, TableRow } from "@/components/podkit/tables/Table";
import { Timestamp } from "@bufbuild/protobuf";
import { timeAgo } from "@/format/time";
import { Button } from "@/components/flexkit/Button";
import { IconTrash } from "@/assets/icons/geist/IconTrash";
import { useMembers } from "@/queries/organization-queries";
import { DeleteSecretModal } from "@/components/projects/DeleteSecretModal";
import { EditSecretModal } from "@/components/projects/EditSecretModal";

export const SecretsTable: FC<{ secrets: PlainSecret[]; onNewSecret: () => void }> = ({ secrets, onNewSecret }) => {
    const [secretToDelete, setSecretToDelete] = useState<PlainSecret | null>(null);
    const [secretToEdit, setSecretToEdit] = useState<PlainSecret | null>(null);

    const sorted = useMemo(() => {
        return [...secrets].sort((a, b) => {
            const aName = a.name.toLowerCase();
            const bName = b.name.toLowerCase();
            return aName.localeCompare(bName);
        });
    }, [secrets]);

    return (
        <div data-testid="project-secrets">
            <Table className="table-fixed">
                <TableHeader className="h-10 [&_th]:border-border-base [&_th]:border-b-[0.5] [&_th]:bg-transparent [&_th]:px-2 [&_th]:py-1">
                    <TableRow>
                        <TableHead className="text-sm font-bold text-content-secondary">Name</TableHead>
                        <TableHead className="text-sm font-bold text-content-secondary">Created by</TableHead>
                        <TableHead className="text-sm font-bold text-content-secondary">Last updated</TableHead>
                        <TableHead className="w-[130px] text-right text-sm font-bold text-content-secondary">
                            <Button
                                variant="secondary"
                                size={"sm"}
                                data-track-label="true"
                                onClick={() => onNewSecret()}
                                data-testid="add-secret-modal-trigger"
                            >
                                New secret
                            </Button>
                        </TableHead>
                    </TableRow>
                </TableHeader>
                <TableBody>
                    {sorted.map((secret) => (
                        <SecretRow
                            key={secret.id}
                            secret={secret}
                            onDelete={(secret) => setSecretToDelete(secret)}
                            onEdit={(secret) => setSecretToEdit(secret)}
                        />
                    ))}
                </TableBody>
            </Table>

            {secretToDelete && <DeleteSecretModal secret={secretToDelete} onClose={() => setSecretToDelete(null)} />}
            {secretToEdit && <EditSecretModal secret={secretToEdit} onClose={() => setSecretToEdit(null)} />}
        </div>
    );
};

const SecretRow: FC<{
    secret: PlainSecret;
    onDelete: (secret: PlainSecret) => void;
    onEdit: (secret: PlainSecret) => void;
}> = ({ secret, onDelete, onEdit }) => {
    const { data: members } = useMembers();

    const lastUpdatedDate = useMemo(() => {
        const ts = secret.updatedAt || secret.createdAt;
        if (!ts) {
            return;
        }
        return new Timestamp(ts).toDate();
    }, [secret.updatedAt, secret.createdAt]);

    const lookup = useMemo(() => {
        return Object.fromEntries(members?.members.map((m) => [m.userId, m]) || []);
    }, [members?.members]);

    return (
        <TableRow
            className="border-0 even:bg-surface-02 [&_td:first-child]:rounded-l-md [&_td:last-child]:rounded-r-md [&_td]:px-2 [&_td]:py-1"
            data-testid={secret.id}
        >
            <TableCell className="p-0">
                <Text className="truncate font-mono text-sm font-bold text-content-primary">{secret.name}</Text>
            </TableCell>
            <TableCell>
                <Text className="truncate text-sm text-content-secondary">
                    {lookup[secret.creator?.id || ""]?.fullName || "-"}
                </Text>
            </TableCell>
            <TableCell>
                {lastUpdatedDate ? (
                    <Text className="truncate text-sm text-content-secondary" title={lastUpdatedDate.toISOString()}>
                        {timeAgo(lastUpdatedDate)}
                    </Text>
                ) : (
                    "-"
                )}
            </TableCell>
            <TableCell>
                <div className="flex items-center justify-end gap-2">
                    <Button
                        data-testid={`edit-secret-${secret.id}-button`}
                        variant="secondary"
                        size="sm"
                        onClick={() => onEdit(secret)}
                    >
                        Edit
                    </Button>
                    <Button
                        size={"md"}
                        data-testid={`delete-secret-${secret.id}-button`}
                        LeadingIcon={IconTrash}
                        variant="ghost"
                        className="border-0 px-1"
                        onClick={() => onDelete(secret)}
                    />
                </div>
            </TableCell>
        </TableRow>
    );
};
