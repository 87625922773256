import { type FC, useCallback } from "react";
import {
    Dialog,
    DialogBody,
    DialogClose,
    DialogContent,
    DialogDescription,
    DialogFooter,
    DialogHeader,
    DialogTitle,
} from "@/components/podkit/modal/Modal";
import { Text } from "@/components/podkit/typography/Text";
import { useToast } from "@/components/podkit/toasts/use-toast";
import { formatError } from "@/utils/errors";
import { useDeleteProject, type PlainProject, type ProjectState } from "@/queries/project-queries";
import { Button } from "@/components/flexkit/Button";
import type { PlainOrganizationMember } from "@/queries/organization-queries";
import { TrackLocations } from "@/hooks/use-segment";

type DeleteProjectModalProps = {
    project: PlainProject;
    state: ProjectState;
    creator: PlainOrganizationMember | undefined;
    currentMember: PlainOrganizationMember | undefined;
    onClose: () => void;
};

export const DeleteProjectModal: FC<DeleteProjectModalProps> = ({
    project,
    onClose,
    state,
}: DeleteProjectModalProps) => {
    const { toast } = useToast();

    const deleteProject = useDeleteProject();

    const onOpenChange = (open: boolean) => {
        if (!open) {
            onClose();
        }
    };

    const handleDeleteProject = useCallback(() => {
        deleteProject.mutate(
            {
                projectId: project.id,
            },
            {
                onSuccess: () => {
                    toast({ title: "Project deleted" });
                    onClose();
                },
                onError: (err) => {
                    toast({ title: "Failed to delete project", description: formatError(err) });
                },
            },
        );
    }, [deleteProject, onClose, project, toast]);

    return (
        <Dialog open onOpenChange={onOpenChange}>
            <DialogContent className="max-w-xl" data-track-location={TrackLocations.ProjectDeleteModal}>
                <DialogHeader>
                    <DialogTitle>Delete project</DialogTitle>
                    <DialogDescription />
                </DialogHeader>

                <DialogBody className="flex flex-col gap-4">
                    <Text>Are you sure you want to delete the project?</Text>
                    <div className="group flex flex-col justify-between gap-4 overflow-x-hidden rounded-xl border border-border-base bg-surface-glass px-4 py-2.5 pl-3">
                        <div className="flex flex-col">
                            <Text className="text-base font-bold">{project.metadata?.name}</Text>
                            <Text className="text-sm text-content-secondary">
                                {state.shared && <span>Shared · used by {project.usedBy?.totalSubjects} members</span>}
                                {!state.shared && <span>Not shared</span>}
                            </Text>
                        </div>
                    </div>
                </DialogBody>

                <DialogFooter className="sm:justify-end">
                    <DialogClose asChild>
                        <Button type="button" variant="secondary" onClick={onClose} data-track-label="true">
                            Cancel
                        </Button>
                    </DialogClose>
                    <Button
                        autoFocus={true}
                        variant="destructive"
                        loading={deleteProject.isPending}
                        onClick={handleDeleteProject}
                        data-track-label="true"
                    >
                        Yes, Delete
                    </Button>
                </DialogFooter>
            </DialogContent>
        </Dialog>
    );
};
