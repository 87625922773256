import { cn } from "@/components/podkit/lib/cn";
import type { OnboardingQuestionnaireStep } from "@/hooks/use-onboarding-questionnaire";
import { TimelineVerticalElipsis } from "@/routes/onboarding/components/TimelineElipsis";
import type { FC } from "react";

export const QuestionareTimeline: FC<{ steps: OnboardingQuestionnaireStep[] }> = ({ steps }) => {
    return (
        <div className="rounded-xl border border-border-light p-0.5">
            <div className="flex flex-col items-center justify-center gap-2">
                {steps.map((step, index) => (
                    <div key={step.id} className="flex flex-col items-center gap-2">
                        <QuestionareTimelineItem step={step} />
                        {index < steps.length - 1 && (
                            <TimelineVerticalElipsis className="block h-[26px] w-[2px] text-content-quaternary" />
                        )}
                    </div>
                ))}
            </div>
        </div>
    );
};

const QuestionareTimelineItem: FC<{ step: OnboardingQuestionnaireStep }> = ({ step }) => {
    return (
        <div
            className={cn(
                "h-5 w-5 rounded-full text-center text-sm leading-5",
                step.state === "active" && "bg-surface-orange text-black",
                step.state === "done" && "bg-content-green text-white",
                step.state === "todo" && "bg-content-quaternary text-white",
            )}
        >
            {step.label}
        </div>
    );
};
