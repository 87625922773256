import { IconPlus } from "@/assets/icons/geist/IconPlus";
import { Button } from "@/components/flexkit/Button";
import {
    DropdownMenu,
    DropdownMenuContent,
    DropdownMenuItem,
    DropdownMenuSeparator,
    DropdownMenuTrigger,
} from "@/components/podkit/dropdown/DropDown";
import { cn } from "@/components/podkit/lib/cn";
import { useToast } from "@/components/podkit/toasts/use-toast";
import { Tooltip } from "@/components/Tooltip";
import { timeAgo } from "@/format/time";
import type { PlainOrganizationMember } from "@/queries/organization-queries";
import type { PlainRunner } from "@/queries/runner-queries";
import { DeleteRunnerModal, ForceDeleteRunnerModal } from "@/components/runners/DeleteRunnerModal";
import { RenameRunnerModal } from "@/components/runners/RenameRunnerModal";
import { RunnerIcon } from "@/components/runners/RunnerIcon";
import { Timestamp } from "@bufbuild/protobuf";
import { Principal } from "gitpod-next-api/gitpod/v1/identity_pb";
import { OrganizationRole } from "gitpod-next-api/gitpod/v1/organization_pb";
import { RunnerKind, RunnerPhase } from "gitpod-next-api/gitpod/v1/runner_pb";
import { MoreHorizontalIcon } from "lucide-react";
import { type FC, useCallback, useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";
import awsLogo from "@/assets/aws.svg";

export const RunnerCard: FC<{
    runner: PlainRunner;
    creator?: PlainOrganizationMember;
    currentMember?: PlainOrganizationMember;
}> = ({ runner, creator, currentMember }) => {
    const navigate = useNavigate();

    const [showRenameModal, setShowRenameModal] = useState(false);
    const [showDeletionModal, setShowDeletionModal] = useState(false);
    const [showForceDeleteModal, setShowForceDeleteModal] = useState(false);
    const { toast } = useToast();

    const handleViewDetails = useCallback(() => {
        navigate(`/settings/runners/${runner.runnerId}`);
    }, [navigate, runner.runnerId]);

    const handleCopyId = useCallback(async () => {
        await navigator.clipboard.writeText(runner.runnerId);
        toast({
            title: `Runner ID copied to clipboard`,
            description: runner.runnerId,
        });
    }, [toast, runner.runnerId]);

    const isCreator =
        runner.creator?.principal === Principal.USER
            ? runner.creator?.id === (currentMember?.userId || "no-member")
            : false;
    const isAdmin = currentMember?.role === OrganizationRole.ADMIN;
    const canEdit = isAdmin || isCreator;
    const canViewDetails = runner.kind === RunnerKind.REMOTE && canEdit;
    const showForceDelete = runner.spec?.desiredPhase === RunnerPhase.DELETED;

    const version = runner.status?.version || "unknown";

    const isRemoteRunner = runner.kind === RunnerKind.REMOTE;
    const isSelectable = isRemoteRunner;

    const handleViewEnvironments = useCallback(() => {
        navigate(`/settings/environments?runner=${runner.runnerId}`);
    }, [navigate, runner.runnerId]);

    const handleOnClick = useCallback(() => {
        if (!isSelectable) {
            return;
        }
        navigate(`/settings/runners/${runner.runnerId}`);
    }, [isSelectable, navigate, runner.runnerId]);

    return (
        <>
            <div
                data-testid={"runner-" + runner.runnerId}
                onClick={handleOnClick}
                className={cn(
                    "flex w-[330px] flex-col gap-2 rounded-xl border-0.5 border-solid border-border-base bg-surface-glass px-5 py-4 text-left text-xs",
                    isSelectable
                        ? "hover:outline hover:outline-1 hover:outline-border-yield focus-visible:ring-1"
                        : "cursor-default",
                )}
            >
                <div className="flex grow">
                    <div className="flex grow flex-col gap-0.5">
                        <div className="flex flex-row">
                            <div className="grow">
                                <RunnerIcon runner={runner} />
                            </div>
                            <div>
                                <DropdownMenu>
                                    <DropdownMenuTrigger
                                        asChild
                                        className="flex rounded-md bg-transparent hover:bg-surface-tertiary"
                                    >
                                        <Button variant="ghost" className="h-6 w-6 p-0" data-testid="actions">
                                            <MoreHorizontalIcon className="h-5 w-5 text-content-primary" />
                                        </Button>
                                    </DropdownMenuTrigger>
                                    <DropdownMenuContent align="end" className="focus-visible:ring-0">
                                        {canViewDetails && (
                                            <DropdownMenuItem onClick={handleViewDetails}>
                                                View Details
                                            </DropdownMenuItem>
                                        )}
                                        {isAdmin && (
                                            <DropdownMenuItem onClick={handleViewEnvironments}>
                                                View Environments
                                            </DropdownMenuItem>
                                        )}
                                        <DropdownMenuItem onClick={handleCopyId}>Copy ID</DropdownMenuItem>
                                        <DropdownMenuSeparator className="bg-content-tertiary/20" />
                                        {canEdit && (
                                            <DropdownMenuItem onClick={() => setShowRenameModal(true)}>
                                                Rename
                                            </DropdownMenuItem>
                                        )}
                                        {canEdit && (
                                            <DropdownMenuItem
                                                className="text-red-500 focus:text-red-500"
                                                onClick={() =>
                                                    showForceDelete
                                                        ? setShowForceDeleteModal(true)
                                                        : setShowDeletionModal(true)
                                                }
                                            >
                                                {showForceDelete ? "Force Delete" : "Delete"}
                                            </DropdownMenuItem>
                                        )}
                                        <DropdownMenuItem className="text-neutral-400">
                                            Version: {version}
                                        </DropdownMenuItem>
                                    </DropdownMenuContent>
                                </DropdownMenu>
                            </div>
                        </div>
                        <Tooltip content={runner.name}>
                            <div className="w-48 min-w-0 truncate text-base font-bold">{runner.name}</div>
                        </Tooltip>
                        <div className="flex grow">{toRunnerType(runner)}</div>
                    </div>
                </div>
                <div>
                    <RunnerPhaseTag runner={runner} />
                </div>
                <div className="flex flex-col">
                    <div className="text-content-secondary">Created {getCreationTime(runner)}</div>
                    <div data-testid="creator" className="text-content-secondary">
                        <span>
                            by{" "}
                            <span>
                                {currentMember?.userId === creator?.userId ? "You" : creator?.fullName || "Unknown"}
                            </span>
                        </span>
                    </div>
                </div>
            </div>
            {showDeletionModal && <DeleteRunnerModal runner={runner} onClose={() => setShowDeletionModal(false)} />}
            {showForceDeleteModal && (
                <ForceDeleteRunnerModal runner={runner} onClose={() => setShowForceDeleteModal(false)} />
            )}
            {showRenameModal && <RenameRunnerModal runner={runner} onClose={() => setShowRenameModal(false)} />}
        </>
    );
};

const toRunnerType = (runner: PlainRunner) => {
    switch (runner.kind) {
        case RunnerKind.LOCAL:
            return "Your computer";
        case RunnerKind.REMOTE:
            return "Amazon EC2";
        default:
            return "Unknown";
    }
};

const getCreationTime = (runner: PlainRunner) => {
    if (!runner.createdAt) {
        return "";
    }
    const date = new Timestamp(runner.createdAt).toDate();
    const relative = timeAgo(date);
    return <span title={date.toISOString()}>{relative}</span>;
};

export const AddNewRunnerCard: FC<{ setShowNewRunnerModal: (show: boolean) => void }> = ({ setShowNewRunnerModal }) => {
    return (
        <div data-testid="add-new-runner-card" className="min-h-40 w-[330px] rounded-lg text-content-secondary">
            <Button
                variant="ghost"
                className="flex h-full w-full flex-col gap-2 border-0.5 border-border-light bg-surface-primary hover:bg-surface-tertiary"
                onClick={() => setShowNewRunnerModal(true)}
            >
                <IconPlus size="base" />
                <div className="text-xs">Setup a runner</div>
                <img src={awsLogo} className="size-[32px]" />
            </Button>
        </div>
    );
};

export const RunnerPhaseTag: FC<{ runner: PlainRunner }> = ({ runner }) => {
    const { fgColor, bgColor, pulse, name, tooltip } = useMemo(() => {
        const actual = runner.status?.phase;
        const message = runner.status?.message || "";
        const desired = runner.spec?.desiredPhase;

        switch (desired) {
            case RunnerPhase.DELETED:
                return {
                    bgColor: "bg-surface-negative/10",
                    fgColor: "content-negative",
                    name: "Pending deletion",
                    pulse: false,
                };
        }

        switch (actual) {
            case RunnerPhase.ACTIVE:
                return {
                    bgColor: "bg-content-positive/10",
                    fgColor: "text-content-positive",
                    name: "Online",
                    pulse: false,
                };
            case RunnerPhase.DEGRADED:
                return {
                    bgColor: "bg-surface-negative/10",
                    fgColor: "content-negative",
                    name: "Active (Degraded)",
                    tooltip: message,
                    pulse: false,
                };
            case RunnerPhase.CREATED:
                return {
                    bgColor: "bg-surface-primary",
                    fgColor: "text-content-orange",
                    name: "Waiting to connect",
                    pulse: false,
                };
            case RunnerPhase.DELETING:
                return {
                    bgColor: "bg-surface-negative/10",
                    fgColor: "content-negative",
                    name: "Pending deletion",
                    pulse: false,
                };
            case RunnerPhase.INACTIVE:
                return {
                    bgColor: "bg-surface-negative/10",
                    fgColor: "content-negative",
                    name: "Offline",
                    pulse: false,
                };
            case RunnerPhase.DELETED:
                return {
                    bgColor: "bg-surface-primary",
                    fgColor: "text-content-secondary",
                    name: "Deleted",
                    pulse: false,
                };
            default:
                return {
                    fgColor: "",
                    bgColor: "",
                    name: "Unknonwn",
                    pulse: false,
                };
        }
    }, [runner]);

    return (
        <div
            className={cn(
                bgColor,
                "inline-flex flex-row items-center rounded-lg border-0.5 border-border-strong/50 px-2 py-[2px]",
            )}
        >
            <Tooltip content={tooltip}>
                <div className={cn("text-sm", pulse ? "animate-pulse" : "animate-none")}>
                    <div className={fgColor}>{name}</div>
                </div>
            </Tooltip>
        </div>
    );
};
