import { useDocumentTitle } from "@/hooks/use-document-title";
import { type FC } from "react";
import { RunnersList } from "@/components/runners/RunnersList";
import { RunnerKind } from "gitpod-next-api/gitpod/v1/runner_pb";
import type { UseListRunnersParams } from "@/queries/runner-queries";

const filter: UseListRunnersParams = {
    kind: RunnerKind.REMOTE,
};

export const RunnersPage: FC = () => {
    useDocumentTitle("Runners");

    return <RunnersList filter={filter} />;
};
