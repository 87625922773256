import { AuthenticatedUserLayout } from "@/components/AuthenticatedUserLayout";
import { ExceptionErrorBoundary } from "@/components/ExceptionHandler";
import { OnboardingSidebar } from "@/components/onboarding/OnboardingSidebar";
import { PageSuspense } from "@/components/PageSuspense";
import { NoSidebar, SidebarLayout } from "@/components/SidebarLayout";
import { useGitpodCookie } from "@/hooks/use-gitpod-cookie";
import { useLoaded } from "@/hooks/use-loaded";
import { useNoMinWidth } from "@/hooks/use-no-min-width";
import { useButtonOrAnchorTracking } from "@/hooks/use-segment";
import { type MenuItem } from "@/menu-item";
import { CreateOrganizationPage } from "@/routes/create-organization/CreateOrganizationPage";
import { EnvironmentInventoryPage } from "@/routes/settings/organization/environment-inventory/EnvironmentInventoryPage";
import { createEnvironmentDetailsRoute } from "@/routes/environments/details/EnvironmentDetailsRoute";
import { GitAuthenticationsPage } from "@/routes/settings/user/git-authentications/GitAuthenticationsPage";
import { JOIN_ORGANIZATION_PATH } from "@/routes/join-organization/join-organization";
import { JoinOrganizationPage } from "@/routes/join-organization/JoinOrganizationPage";
import { LocalRunner, QuitApp } from "@/routes/local-runner/LocalRunner";
import LoginPage from "@/routes/LoginPage";
import { ManageOrganizationPage } from "@/routes/settings/organization/manage-organization/ManageOrganizationPage";
import { InviteMembersPage } from "@/routes/settings/organization/members/InviteMembersPage";
import { MembersPage } from "@/routes/settings/organization/members/MembersPage";
import { NotFound } from "@/routes/NotFound";
import { FlexUpgradeModal } from "@/routes/onboarding/FlexUpgradeModal";
import { OnboardingPage } from "@/routes/onboarding/OnboardingPage";
import { OnboardingQuestionnairePage } from "@/routes/onboarding/OnboardingQuestionnairePage";
import { PersonalAccessTokensPage } from "@/routes/settings/user/personal-access-tokens/PersonalAccessTokensPage";
import { ProjectsPage } from "@/routes/projects/ProjectsPage";
import { RunnerDetailsPage } from "@/components/runners/details/RunnerDetailsPage";
import { RunnersPage } from "@/routes/settings/organization/runners/RunnersPage";
import { SigninDesktopPage } from "@/routes/signin-desktop/SigninDesktopPage";
import { type FC, type PropsWithChildren } from "react";
import {
    createBrowserRouter,
    createMemoryRouter,
    Navigate,
    Outlet,
    RouterProvider,
    type RouteObject,
} from "react-router-dom";
import { UserProfilePage } from "@/routes/settings/user/profile/UserProfilePage";
import { SettingsPageLayout } from "@/components/settings/SettingsPageLayout";
import { ProjectDetailsPage } from "@/routes/projects/details/ProjectDetailsPage";
import { LoginAndSecurityPage } from "@/routes/settings/login/LoginAndSecurityPage";
import { Flags } from "@/feature-flags";

export const App: FC = () => {
    useLoaded();
    useButtonOrAnchorTracking();
    useGitpodCookie();
    return <RouterProvider router={NewSidebarRouter} />;
};

const NoMinWidth: FC<PropsWithChildren> = ({ children }) => {
    useNoMinWidth();
    return children;
};

// eslint-disable-next-line react-refresh/only-export-components
export const settingsRoutes: RouteObject = {
    element: <SettingsPageLayout />,
    path: "/settings",
    children: [
        {
            index: true,
            handle: {},
            element: <Navigate to={"/settings/runners"} replace={true} />,
        },
        {
            // /settings/preferences is deprecated, redirect to /settings/profile
            path: "preferences",
            element: <Navigate to={"/settings/profile"} replace={true} />,
        },
        {
            path: "profile",
            element: <UserProfilePage />,
            handle: {
                label: "Profile",
                menuItem: {
                    label: "Profile",
                    section: "My account",
                    to: "/settings/profile",
                    isAdminRole: false,
                } satisfies MenuItem,
            },
        },
        {
            path: "git-authentications",
            element: <GitAuthenticationsPage />,
            handle: {
                label: "Git authentications",
                menuItem: {
                    label: "Git authentications",
                    section: "My account",
                    to: "/settings/git-authentications",
                    isAdminRole: false,
                } satisfies MenuItem,
            },
        },
        {
            path: "personal-access-tokens",
            element: <PersonalAccessTokensPage />,
            handle: {
                label: "Personal access tokens",
                menuItem: {
                    label: "Personal access tokens",
                    section: "My account",
                    to: "/settings/personal-access-tokens",
                    isAdminRole: false,
                } satisfies MenuItem,
            },
        },
        {
            path: "manage-organization",
            element: <ManageOrganizationPage />,
            handle: {
                label: "General",
                menuItem: {
                    label: "General",
                    section: "Organization",
                    to: "/settings/manage-organization",
                    isAdminRole: true,
                } satisfies MenuItem,
            },
        },
        {
            path: "members",
            handle: {
                label: "Members",
                menuItem: {
                    label: "Members",
                    section: "Organization",
                    to: "/settings/members",
                    isAdminRole: false,
                } satisfies MenuItem,
            },
            children: [
                {
                    index: true,
                    element: <MembersPage />,
                },
                {
                    path: "invite",
                    element: <InviteMembersPage />,
                    handle: {
                        label: "Invite members",
                    },
                },
            ],
        },
        {
            path: "runners",
            handle: {
                label: "Runners",
                menuItem: {
                    label: "Runners",
                    section: "Organization",
                    to: "/settings/runners",
                    isAdminRole: false,
                } satisfies MenuItem,
            },
            children: [
                {
                    index: true,
                    element: <RunnersPage />,
                },
                {
                    path: ":runnerId",
                    element: <RunnerDetailsPage />,
                    handle: {
                        label: "Details",
                    },
                },
            ],
        },
        {
            path: "environments",
            element: <EnvironmentInventoryPage />,
            handle: {
                label: "Environments",
                menuItem: {
                    label: "Environments",
                    section: "Organization",
                    to: "/settings/environments",
                    isAdminRole: true,
                } satisfies MenuItem,
            },
        },
        {
            path: "login",
            element: <LoginAndSecurityPage />,
            handle: {
                label: "Log in and security",
                menuItem: {
                    label: "Log in and security",
                    section: "Organization",
                    to: "/settings/login",
                    isAdminRole: true,
                    featureFlag: Flags.LoginAndSecurityEnabled,
                } satisfies MenuItem,
            },
        },
    ],
};

// eslint-disable-next-line react-refresh/only-export-components
export const appRoutes: RouteObject[] = [
    {
        // Pages using Sidebar
        element: (
            <ExceptionErrorBoundary>
                <SidebarLayout />
            </ExceptionErrorBoundary>
        ),
        children: [
            {
                element: (
                    <>
                        <AuthenticatedUserLayout />
                        <FlexUpgradeModal />
                    </>
                ),
                children: [
                    {
                        index: true,
                        element: (
                            <Navigate
                                to={{ pathname: "/projects", hash: document.location.hash }}
                                replace
                                state={{ showCreateModal: !!document.location.hash }}
                            />
                        ),
                    },
                    settingsRoutes,
                    {
                        path: "projects",
                        handle: {
                            label: "Projects",
                        },
                        element: (
                            <PageSuspense>
                                <Outlet />
                            </PageSuspense>
                        ),
                        children: [
                            {
                                index: true,
                                element: <ProjectsPage />,
                            },
                            {
                                path: ":projectId",
                                element: <ProjectDetailsPage />,
                                handle: {
                                    // Will be set dynamically
                                    label: undefined,
                                },
                            },
                        ],
                    },
                    createEnvironmentDetailsRoute(),
                    {
                        path: "/onboarding/enabler",
                        element: <OnboardingPage />,
                    },
                    {
                        path: "create",
                        element: <Navigate to="/projects" replace state={{ showCreateModal: true }} />,
                    },
                ],
            },
        ],
    },
    // Onboarding pages using custom sidebar
    {
        element: (
            <ExceptionErrorBoundary>
                <SidebarLayout customSidebar={<OnboardingSidebar />} />
            </ExceptionErrorBoundary>
        ),
        children: [
            {
                path: "/",
                element: <AuthenticatedUserLayout />,
                children: [
                    {
                        path: "/create-organization",
                        element: (
                            <PageSuspense>
                                <CreateOrganizationPage />
                            </PageSuspense>
                        ),
                    },
                    {
                        path: "/onboarding/primary-interests",
                        element: <OnboardingQuestionnairePage />,
                        handle: {
                            label: "Primary Interests",
                        },
                    },
                ],
            },
        ],
    },
    // Pages without a Sidebar
    {
        element: (
            <ExceptionErrorBoundary>
                <SidebarLayout customSidebar={<NoSidebar />} />
            </ExceptionErrorBoundary>
        ),
        children: [
            {
                path: "/",
                element: <AuthenticatedUserLayout />,
                children: [
                    {
                        path: `${JOIN_ORGANIZATION_PATH}/:inviteId?`,
                        element: (
                            <PageSuspense>
                                <JoinOrganizationPage />
                            </PageSuspense>
                        ),
                    },
                ],
            },
        ],
    },
    // UNAUTHENTICATED ROUTES
    // Pages without a Sidebar
    {
        element: (
            <ExceptionErrorBoundary>
                <NoMinWidth>
                    <SidebarLayout customSidebar={<NoSidebar />} />
                </NoMinWidth>
            </ExceptionErrorBoundary>
        ),
        children: [
            {
                path: "/confirm/signin",
                element: (
                    <PageSuspense>
                        <SigninDesktopPage />
                    </PageSuspense>
                ),
            },
            {
                path: "/login",
                element: (
                    <PageSuspense>
                        <LoginPage />
                    </PageSuspense>
                ),
            },
            {
                path: "/local-runner",
                element: (
                    <PageSuspense>
                        <LocalRunner />
                    </PageSuspense>
                ),
            },
            {
                path: "/quit-app",
                element: (
                    <PageSuspense>
                        <QuitApp />
                    </PageSuspense>
                ),
            },
            {
                path: "*",
                element: <NotFound />,
            },
        ],
    },
];

export const NewSidebarRouter = createBrowserRouter(appRoutes);
export const CreateTestRouter = (opts?: { initialEntries?: string[] }) => createMemoryRouter(appRoutes, opts);
