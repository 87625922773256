import {
    DialogBody,
    DialogClose,
    DialogDescription,
    DialogFooter,
    DialogHeader,
    DialogTitle,
} from "@/components/podkit/modal/Modal";
import { Text } from "@/components/podkit/typography/Text";
import { useCallback, type FC, type FormEvent } from "react";
import { Button } from "@/components/flexkit/Button";
import type { SCMIntegration } from "gitpod-next-api/gitpod/v1/runner_configuration_pb";
import { useDeleteSCMIntegration } from "@/queries/runner-configuration-queries";
import { useToast } from "@/components/podkit/toasts/use-toast";
import { formatError } from "@/utils/errors";

export const SourceControlProviderRemoveModal: FC<{
    runnerId: string;
    integration: SCMIntegration;
    onClose: () => void;
}> = ({ runnerId, integration, onClose }) => {
    const { toast } = useToast();
    const deleteSCMIntegration = useDeleteSCMIntegration(runnerId);
    const handleSubmit = useCallback(
        async (event: FormEvent<HTMLFormElement>) => {
            event.preventDefault();
            try {
                if (!integration.id) {
                    return;
                }
                await deleteSCMIntegration.mutateAsync(integration.id);
                toast({
                    title: "Removed provider",
                    description: "The provider has been successfully removed.",
                });
                onClose();
            } catch (e) {
                toast({
                    title: "Failed to remove provider",
                    description: formatError(e),
                });
            }
        },
        [deleteSCMIntegration, onClose, toast, integration.id],
    );

    return (
        <form onSubmit={handleSubmit} id="provider-integration" name="provider-integration">
            <DialogHeader>
                <DialogTitle>Remove repository access</DialogTitle>
                <DialogDescription />
            </DialogHeader>

            <DialogBody>
                <div className="flex flex-col gap-2">
                    <Text className="text-base">
                        Are you sure you&#x2019;d like to remove access to {integration.host} for this runner?
                    </Text>
                    <Text className="text-base font-bold">This may break existing environments that are in use.</Text>
                </div>
            </DialogBody>

            <DialogFooter className="sm:justify-end">
                <DialogClose asChild>
                    <Button
                        type="button"
                        variant="secondary"
                        onClick={onClose}
                        disabled={deleteSCMIntegration.isPending}
                    >
                        Cancel
                    </Button>
                </DialogClose>
                <Button autoFocus={false} variant="destructive" type="submit" loading={deleteSCMIntegration.isPending}>
                    Yes, Remove
                </Button>
            </DialogFooter>
        </form>
    );
};
