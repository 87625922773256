import { useEffect, useState, useImperativeHandle, forwardRef, useId, useCallback } from "react";
import InputWithSuggestions from "@/components/podkit/forms/InputWithSuggestions";
import { Label } from "@/components/podkit/forms/Label";
import { type PropsWithClassName, cn } from "@/components/podkit/lib/cn";
import { useContextUrlRememberer } from "@/hooks/use-context-url";

type Props = {
    label?: string;
    value?: string;
    name?: string;
    disabled?: boolean;
    errorMessage?: string;
    autoFocus?: boolean;
    onChange?: (value: string) => void;
};
export const ContextUrlInput = forwardRef(
    (
        { label, value, onChange, name, disabled, className, errorMessage, autoFocus }: Props & PropsWithClassName,
        ref,
    ) => {
        const [previousContextURLs, setPreviousContextURLs] = useState<string[]>([]);
        const { remember, listContextUrls } = useContextUrlRememberer();
        const elId = useId();

        const handleChange = useCallback(
            (value: string) => {
                value = value.trim();
                if (onChange) {
                    onChange(value);
                }
            },
            [onChange],
        );

        useImperativeHandle(ref, () => ({
            remember,
        }));

        useEffect(() => {
            let isMounted = true;
            listContextUrls()
                .then((l) => {
                    if (isMounted) {
                        setPreviousContextURLs(l);
                    }
                })
                .catch((e) => {
                    console.error(e);
                });
            return () => {
                isMounted = false;
            };
        }, [listContextUrls]);

        return (
            <div className={cn("flex items-center space-x-2", className)}>
                <div className="flex w-full flex-col gap-2">
                    <Label htmlFor={elId} className="font-medium">
                        {label}
                    </Label>
                    <InputWithSuggestions
                        id={elId}
                        data-testid="context-url-input"
                        name={name}
                        value={value}
                        disabled={disabled}
                        suggestions={previousContextURLs}
                        placeholder="https://github.com/microsoft/vscode-remote-try-go"
                        onChange={handleChange}
                        error={errorMessage}
                        autoFocus={autoFocus}
                    />
                </div>
            </div>
        );
    },
);

ContextUrlInput.displayName = "ContextUrlInput";
