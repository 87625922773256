import { useDocumentTitle } from "@/hooks/use-document-title";
import { useState, useCallback } from "react";
import { type FC } from "react";
import { Heading2 } from "@/components/podkit/typography/Headings";
import { useNavigate } from "react-router-dom";
import { Button } from "@/components/flexkit/Button";
import type { CheckboxProps, CheckedState } from "@radix-ui/react-checkbox";
import {
    OnboardingQuestionnaireId,
    Questions,
    type Question,
    useOnboardingQuestionnaire,
    YourRoleSegmentTrackingNameMap,
    WhatToAchieveSegmentTrackingNameMap,
} from "@/hooks/use-onboarding-questionnaire";
import React from "react";
import { cn } from "@/components/podkit/lib/cn";
import { CheckIcon } from "lucide-react";
import * as ReactCheckbox from "@radix-ui/react-checkbox";
import { QuestionareTimeline } from "@/routes/onboarding/components/QuestionnaireTimeline";
import { useSegmentTrack } from "@/hooks/use-segment";
import { useGetAccount } from "@/queries/account-queries";

export const OnboardingQuestionnairePage: FC = () => {
    useDocumentTitle("Onboarding - Primary Interests");
    const { data: account } = useGetAccount();
    const navigate = useNavigate();
    const onboardingQuestionnaire = useOnboardingQuestionnaire();
    const segmentTrack = useSegmentTrack();

    const activeStep = onboardingQuestionnaire.steps.steps.find((s) => s.state === "active");

    const next = useCallback(() => {
        navigate("/onboarding/enabler", { replace: true });
    }, [navigate]);

    const handleContinue = useCallback(() => {
        if (!activeStep) {
            return;
        }

        switch (activeStep.id) {
            case OnboardingQuestionnaireId.YourRole:
                if (activeStep.data) {
                    segmentTrack("user_role", { role: YourRoleSegmentTrackingNameMap[activeStep.data.selection] });
                }
                break;
            case OnboardingQuestionnaireId.WhatToAchieve:
                if (activeStep.data) {
                    segmentTrack("user_use_case", {
                        ...Object.fromEntries(
                            Object.entries(WhatToAchieveSegmentTrackingNameMap).map(([k, v]) => {
                                return [v, activeStep.data!.selections.includes(k)];
                            }),
                        ),
                        auth_principal: "account",
                        auth_id: account?.id,
                        account_id: account?.id,
                    });
                }
                break;
        }

        onboardingQuestionnaire.complete(activeStep.id);
        if (activeStep.id === OnboardingQuestionnaireId.WhatToAchieve) {
            next();
        }
    }, [next, activeStep, onboardingQuestionnaire, segmentTrack, account]);

    const handleSkip = useCallback(() => {
        if (!activeStep) {
            return;
        }

        onboardingQuestionnaire.complete(activeStep.id);
        if (activeStep.id === OnboardingQuestionnaireId.WhatToAchieve) {
            next();
        }
    }, [next, activeStep, onboardingQuestionnaire]);

    const handleOnChange = useCallback(
        (selectedOptions: string[]) => {
            if (!activeStep) {
                return;
            }

            if (activeStep.id === OnboardingQuestionnaireId.YourRole) {
                onboardingQuestionnaire.updateData({
                    id: activeStep.id,
                    data: selectedOptions.length ? { selection: selectedOptions[0] } : undefined,
                });
            } else if (activeStep.id === OnboardingQuestionnaireId.WhatToAchieve) {
                onboardingQuestionnaire.updateData({
                    id: activeStep.id,
                    data: selectedOptions.length ? { selections: selectedOptions } : undefined,
                });
            }
        },
        [activeStep, onboardingQuestionnaire],
    );

    if (!activeStep || activeStep.id === OnboardingQuestionnaireId.CreateOrg) {
        return null;
    }

    return (
        <div className="flex h-full w-full items-center p-8" data-testid="primary-interests-page">
            <main className="flex w-full items-center gap-2">
                <div className="flex grow justify-center">
                    <QuestionComponent
                        key={activeStep.id}
                        question={Questions[activeStep.id]}
                        onChange={handleOnChange}
                        onContinue={handleContinue}
                        onSkip={handleSkip}
                    />
                </div>
                <QuestionareTimeline steps={onboardingQuestionnaire.steps.steps} />
            </main>
        </div>
    );
};

type QuestionsProps = {
    question: Question;
    onContinue: (selectedOptions: string[]) => void;
    onChange: (selectedOptions: string[]) => void;
    onSkip: () => void;
};

const QuestionComponent: FC<QuestionsProps> = ({ question, onContinue, onChange, onSkip }) => {
    const [selectedOptions, setSelectedOptions] = useState<string[]>([]);

    const handleCheckChange = useCallback(
        (checkedState: CheckedState, option: string) => {
            setSelectedOptions((prevSelected) => {
                let updatedSelectedOptions = prevSelected;
                if (checkedState) {
                    if (!prevSelected.includes(option)) {
                        updatedSelectedOptions = question.multiSelection ? [...prevSelected, option] : [option];
                    }
                } else {
                    updatedSelectedOptions = prevSelected.filter((s) => s !== option);
                }
                onChange(updatedSelectedOptions);
                return updatedSelectedOptions;
            });
        },
        [question, onChange],
    );

    return (
        <div className="flex max-w-[360px] gap-6">
            <div className="flex flex-col items-center gap-6">
                <Heading2 className="text-center text-2xl font-normal">{question.title}</Heading2>
                <div className="flex w-full flex-col items-stretch gap-2">
                    {question.options.map((option) => {
                        const isSelected = selectedOptions.includes(option);
                        return (
                            <label
                                key={option}
                                className={cn(
                                    "flex items-center justify-between rounded-xl border border-border-light px-4 py-2",
                                    isSelected && "border-content-primary",
                                )}
                            >
                                <span
                                    className={cn(
                                        "text-base text-content-secondary",
                                        isSelected && "text-content-primary",
                                    )}
                                >
                                    {option}
                                </span>
                                <CustomCheckBox
                                    className="ml-2 shrink-0"
                                    checked={isSelected}
                                    onCheckedChange={(checkedState) => handleCheckChange(checkedState, option)}
                                />
                            </label>
                        );
                    })}
                </div>
                <div className="flex w-full flex-col gap-2">
                    <Button
                        onClick={() => onContinue(selectedOptions)}
                        variant="secondary"
                        size="lg"
                        className="w-full bg-content-quaternary"
                        disabled={selectedOptions.length <= 0}
                        data-track-label="true"
                    >
                        Continue
                    </Button>
                    <Button
                        onClick={onSkip}
                        variant="ghost"
                        size="lg"
                        className="w-full"
                        id="skip"
                        data-track-label="true"
                    >
                        Skip
                    </Button>
                </div>
            </div>
        </div>
    );
};

const CustomCheckBox = React.forwardRef<React.ElementRef<typeof ReactCheckbox.Root>, CheckboxProps>(
    ({ className, ...props }, ref) => {
        return (
            <ReactCheckbox.Root
                className={cn(
                    "size-4",
                    "rounded-full border",
                    "flex items-center justify-center",
                    "focus-visible:ring-kumquat-base/70 transition-opacity duration-100 focus:outline-none focus-visible:ring-2 focus-visible:ring-offset-0",
                    // checked state colors
                    "data-[state=checked]:border-content-primary data-[state=checked]:hover:opacity-80 data-[state=checked]:disabled:opacity-40",
                    // unchecked state colors
                    "data-[state=unchecked]:border-content-quaternary data-[state=unchecked]:bg-surface-secondary data-[state=unchecked]:hover:bg-surface-tertiary data-[state=unchecked]:disabled:bg-surface-tertiary",
                    className,
                )}
                {...props}
                ref={ref}
            >
                <span className="flex size-3 items-center justify-center">
                    <ReactCheckbox.Indicator>
                        <CheckIcon className="pt-0.25 size-3 text-content-primary" />
                    </ReactCheckbox.Indicator>
                </span>
            </ReactCheckbox.Root>
        );
    },
);
CustomCheckBox.displayName = ReactCheckbox.Root.displayName;
