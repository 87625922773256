import gitpodLogoLarge from "@/assets/gitpod-logo-large.svg";
import { IconGitHub } from "@/assets/icons/geist/IconGitHub";
import { IconGoogle } from "@/assets/icons/geist/IconGoogle";
import { IconInfo } from "@/assets/icons/geist/IconInfo";
import { Button } from "@/components/flexkit/Button";
import { SHOW_NUDGE_DOWNLOAD_APP } from "@/components/NudgeDownloadApp";
import { LoadingState } from "@/components/podkit/loading/LoadingState";
import { useToast } from "@/components/podkit/toasts/use-toast";
import { Heading1, Subheading } from "@/components/podkit/typography/Headings";
import { ExternalLink } from "@/components/podkit/typography/Link";
import { Text } from "@/components/podkit/typography/Text";
import { useDocumentTitle } from "@/hooks/use-document-title";
import { useBootIntercom } from "@/hooks/use-intercom";
import { useLocalRunner } from "@/hooks/use-local-runner";
import { useLocalStorage } from "@/hooks/use-local-storage";
import { appMainService } from "@/ipc";
import { useGetAccount } from "@/queries/account-queries";
import { formatError } from "@/utils/errors";
import { LocalRunnerStatus } from "frontend-shared/local-runner";
import { useCallback, useEffect, type FC } from "react";
import { Navigate, useLocation } from "react-router-dom";

export const LoginPage: FC = () => {
    useDocumentTitle("Login");
    useBootIntercom();

    const location = useLocation();
    const redirect = (location.search && new URLSearchParams(location.search).get("redirect")) || "/";

    const { data: account, isLoading: isLoadingAccount } = useGetAccount();

    const localRunner = useLocalRunner();

    // Wait for the user to be loaded
    if (!account && isLoadingAccount && localRunner.loading) {
        return <LoadingState />;
    }

    if (LocalRunnerStatus.needsRunnerScreen(localRunner.status)) {
        return <Navigate to="/local-runner" />;
    }

    // If user is already logged in, redirect
    if (account && !isLoadingAccount) {
        return <Navigate to={redirect} replace />;
    }

    return (
        <div
            data-testid="login-page"
            className="flex h-full w-full flex-col items-center justify-between bg-surface-01"
        >
            <div className="h-0 w-full" />
            <div className="mx-auto flex max-w-[368px] flex-col content-center items-center gap-7">
                <img src={gitpodLogoLarge} alt="Gitpod logo" width="64" height="64" />
                <div className="flex flex-col items-center gap-2">
                    <Heading1 className="text-center text-2xl font-medium tracking-tight text-content-primary">
                        Gitpod Flex
                    </Heading1>
                    <Text className="max-w-xs text-center text-xl text-content-secondary">
                        Automated, standardized development environments
                    </Text>
                </div>
                {localRunner.status ? <DesktopSignin /> : <SSOLogin redirect={redirect} />}
            </div>
            <Text className="text-base text-content-primary">
                By signing in, you agree to our{" "}
                <ExternalLink href="https://www.gitpod.io/terms" className="text-content-orange">
                    terms of service
                </ExternalLink>{" "}
                and{" "}
                <ExternalLink href="https://www.gitpod.io/privacy" className="text-content-orange">
                    privacy policy
                </ExternalLink>
                .
            </Text>
        </div>
    );
};

type SSOLoginProps = {
    redirect: string;
};

const SSOLogin: FC<SSOLoginProps> = (props) => {
    const { setValue: setShowNudge } = useLocalStorage(SHOW_NUDGE_DOWNLOAD_APP, "false");
    const location = useLocation();
    const fromPath = (props.redirect || location.state?.from?.pathname || "/") as string;
    const params = new URLSearchParams(location.search);
    const returnTo = params.get("returnTo") || new URL(fromPath, window.location.origin).toString();
    const errorParm = params.get("error");
    const errorDescriptionParm = params.get("error_description");

    if (errorParm) {
        console.log("error param on login page", errorParm, errorDescriptionParm);
    }

    useEffect(() => {
        setShowNudge("true");
    }, [setShowNudge]);

    return (
        <div className="flex w-full flex-col items-stretch gap-2">
            <a
                id="signin"
                href={`/auth/oidc/start?returnTo=${encodeURIComponent(returnTo)}&issuer=https://accounts.google.com`}
            >
                <Button
                    data-track-label="true"
                    size="lg"
                    variant={"secondary"}
                    LeadingIcon={IconGoogle}
                    className="w-full"
                >
                    <span className="flex-grow text-center">Continue with Google</span>
                </Button>
            </a>
            <a id="signin-github" href={`/auth/oidc/start?returnTo=${encodeURIComponent(returnTo)}&issuer=github.com`}>
                <Button
                    data-track-label="true"
                    size="lg"
                    variant={"secondary"}
                    LeadingIcon={IconGitHub}
                    className="w-full"
                >
                    <span className="flex-grow text-center">Continue with GitHub</span>
                </Button>
            </a>
            <Button data-track-label="true" size="lg" variant={"link"} className="w-full" asChild>
                <ExternalLink href="https://gitpod.io/login">Log in to Gitpod Classic</ExternalLink>
            </Button>
            {errorParm && (
                <div className="mt-6 flex flex-row gap-3 rounded-xl border border-border-base bg-surface-glass px-3 py-4">
                    <IconInfo size="base" className="text-content-orange" />
                    <div className="flex w-full grow flex-col gap-2">
                        <Text className="text text-base">
                            Oops! Looks like your sign-in was interrupted. Please try again!
                        </Text>
                        {errorDescriptionParm && <Text className="text-sm">Provider says: {errorDescriptionParm}</Text>}
                    </div>
                </div>
            )}
        </div>
    );
};

const DesktopSignin: FC = () => {
    const { toast } = useToast();
    const cpHost = window.location.origin;

    const handleConnectToGitpod = useCallback(async () => {
        const result = await appMainService!.signin({ host: cpHost });
        if (result === "timeout") {
            toast({ title: "Timed out", description: "Please try again" });
            return;
        }
        const parsed = parseSigninResult(result);
        if (!parsed) {
            toast({ title: "Unexpected parse error", description: "Please try again" });
            return;
        }
        try {
            // clear cookies first
            await fetch("/auth/oidc/logout", {
                method: "POST",
                credentials: "same-origin",
            });
        } catch {
            // explicitly ignore as we're about to login using the desktop flow
        }
        try {
            const response = await fetch("/auth/desktop/callback", {
                method: "POST",
                credentials: "same-origin",
                body: new URLSearchParams(parsed),
            });
            if (!response.ok) {
                throw new Error(response.statusText);
            }
            window.location.href = "/";
        } catch (error) {
            toast({ title: "Unexpected parse error", description: formatError(error) });
        }
    }, [cpHost, toast]);

    return (
        <>
            <Subheading>Sign into {cpHost}</Subheading>
            <div className="my-8" onClick={() => handleConnectToGitpod()}>
                <Button id="signin" variant={"secondary"} size={"lg"} data-track-label="true">
                    Sign into Gitpod
                </Button>
            </div>
        </>
    );
};

function parseSigninResult(result: string) {
    try {
        // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
        const asObject = JSON.parse(result);
        if (typeof asObject === "object" && "code" in asObject && "code_verifier" in asObject) {
            return {
                code: asObject.code as string,
                code_verifier: asObject.code_verifier as string,
            };
        }
    } catch (error) {
        console.error("error processing signin result", error);
    }
}

export default LoginPage;
