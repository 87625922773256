import { IconEarlyAccess } from "@/assets/icons/geist/IconEarlyAccess";
import { IconGitpodEngraved } from "@/assets/icons/geist/IconGitpodEngraved";
import { IconGrid } from "@/assets/icons/geist/IconGrid";
import { IconNewEnvironment } from "@/assets/icons/geist/IconNewEnvironment";
import { Button } from "@/components/flexkit/Button";
import { NudgeDownloadApp } from "@/components/NudgeDownloadApp";
import { AnnouncementBanner } from "@/components/AnnouncementBanner";
import { OrgSwitcher } from "@/components/OrgSwitcher";
import { SidebarButton } from "@/components/SidebarButton";
import { SidebarSectionSettings } from "@/components/settings/SidebarSectionSettings";
import { Tooltip } from "@/components/Tooltip";
import { useStreamEvents } from "@/hooks/use-stream-events";
import { useTheme } from "@/hooks/use-theme";
import { CreateEnvironmentModal } from "@/routes/environments/create/CreateEnvironment";
import { SidebarEnvironmentList } from "@/routes/environments/SidebarEnvironmentList";
import { OnboardingSidebarSection } from "@/routes/onboarding/OnboardingSidebarSection";
import { useEffect, useState, type FC, type PropsWithChildren, type ReactNode } from "react";
import { Outlet, useLocation } from "react-router-dom";
import { getPrincipal, onDidChangePrincipal } from "@/principal";
import { TrackLocations } from "@/hooks/use-segment";

export const SidebarLayout: FC<{ customSidebar?: ReactNode }> = ({ customSidebar }) => {
    useStreamEvents();
    // This ensures that we trigger a re-render when the principal changes - this is needed to
    // ensure we re-render when the user is updated.
    const [, setPrincipal] = useState<string | null>(null);
    useEffect(() => {
        return onDidChangePrincipal(() => {
            setPrincipal(getPrincipal());
        });
    }, [setPrincipal]);
    return (
        <div className="app-background-resetting">
            <div className="app-background h-dvh">
                {window.ipcRenderer && <DesktopTitlebar />}
                <div className="flex h-dvh flex-row">
                    <div className="hidden md:block" data-track-location={TrackLocations.Sidebar}>
                        {customSidebar ? customSidebar : <Sidebar />}
                    </div>
                    <div className="h-dvh max-h-dvh w-full flex-grow overflow-hidden p-2 md:pl-0">
                        <div className="h-full w-full rounded-lg border-0.5 border-border-base bg-surface-01 p-0.5 shadow-content-surface dark:shadow-none">
                            <MainContent>
                                <Outlet />
                            </MainContent>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

/**
 * This component is intended to be used in the Desktop Application where we use a custom title bar.
 * The component ensures that the window can still be dragged around.
 */
const DesktopTitlebar: FC = () => {
    return (
        <div className="fixed h-8 w-full">
            <div className="h-8 w-full [-webkit-app-region:drag]" />
        </div>
    );
};

export const NoSidebar: FC = () => {
    return <div className="pr-2" />;
};

export const Sidebar: FC = () => {
    return (
        <div className="h-dvh w-[18.5rem] min-w-[18.5rem]">
            <div data-testid="sidebar" className="flex h-dvh w-full flex-col pb-[6px] pl-2 pt-10">
                <div className="pl-2 pr-2">
                    <SidebarHeader />
                </div>
                <div className="scrollbar-gutter-stable mr-0.5 flex-grow overflow-y-auto overflow-x-hidden pr-[3px] pt-2">
                    <SidebarContent />
                </div>
                <div className="pb-2">
                    <SidebarFooter />
                </div>
            </div>
        </div>
    );
};

const SidebarHeader: FC = () => {
    const [showNewEnvModal, setShowNewEnvModal] = useState(false);
    return (
        <>
            <div className="flex flex-row justify-between gap-2">
                <div className="flex min-w-16 rounded-lg hover:bg-surface-03">
                    <OrgSwitcher data-track-location={TrackLocations.SidebarOrgSwitcher} />
                </div>

                <Tooltip content="Create environment">
                    <Button
                        id="new-environment"
                        variant={"secondary"}
                        size={"md"}
                        LeadingIcon={IconNewEnvironment}
                        onClick={() => setShowNewEnvModal(true)}
                        data-track-label="true"
                    />
                </Tooltip>
            </div>
            {showNewEnvModal && (
                <CreateEnvironmentModal
                    onClose={() => setShowNewEnvModal(false)}
                    data-track-location={TrackLocations.SidebarCreateEnvironmentModal}
                />
            )}
        </>
    );
};

const SidebarFooter: FC = () => {
    const { effectiveTheme: variant } = useTheme();
    return (
        <>
            <div className="mt-4 flex min-h-6 w-full flex-col items-center justify-center gap-[4px]">
                <AnnouncementBanner />
                <NudgeDownloadApp>
                    <IconGitpodEngraved variant={variant} className="block h-6 w-6 shadow-engraving" />
                </NudgeDownloadApp>
                <IconEarlyAccess variant={variant} className="dark:fill-surface-01/20" />
            </div>
        </>
    );
};

const SidebarContent: FC = () => {
    const location = useLocation();
    return (
        <>
            <div className="flex flex-col gap-1 pl-2">
                <OnboardingSidebarSection />
                <SidebarButton
                    icon={<IconGrid size="lg" />}
                    label="Projects"
                    active={location.pathname === "/projects" || location.pathname === "/"}
                    to="/projects"
                />
                <SidebarSectionSettings />
                <div className="m-2 border-t-[1px] border-surface-invert/10" />
                <SidebarEnvironmentList />
            </div>
        </>
    );
};

const MainContent: FC<PropsWithChildren> = ({ children }) => {
    return <div className="h-full w-full overflow-x-auto overflow-y-auto p-6">{children}</div>;
};
