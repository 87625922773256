import { TimelineElipsis } from "@/routes/onboarding/components/TimelineElipsis";
import { TimelinePill } from "@/routes/onboarding/components/TimelinePill";
import type { RunnerConfigurationPhase } from "@/routes/onboarding/use-runner-configuration-progress";
import type { FC } from "react";

export const Timeline: FC<{ phases: RunnerConfigurationPhase[] }> = ({ phases }) => {
    return (
        <div className="flex w-full flex-col">
            <div className="flex w-full items-center justify-center gap-2">
                {phases.map((phase, index) => (
                    <div key={phase.id} className="flex items-center gap-2">
                        <TimelinePill phase={phase} />
                        {index < phases.length - 1 && (
                            <TimelineElipsis className="block h-[2px] w-[14px] text-content-quaternary" />
                        )}
                    </div>
                ))}
            </div>
        </div>
    );
};
