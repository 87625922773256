import type { RunnerConfigurationPhaseState } from "@/routes/onboarding/use-runner-configuration-progress";
import type { FC } from "react";

export const TimelineDot: FC<{ state: RunnerConfigurationPhaseState }> = ({ state }) => {
    switch (state) {
        case "done":
            return (
                <svg
                    className="text-content-green"
                    width="16"
                    height="16"
                    viewBox="0 0 16 16"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                >
                    <path
                        d="M4 8.5L7 11L12 5"
                        stroke="#2ED24D"
                        strokeWidth="2"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                    />
                </svg>
            );
        case "active":
            return (
                <svg
                    className="text-content-orange"
                    width="16"
                    height="16"
                    viewBox="0 0 16 16"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                >
                    <path
                        fillRule="evenodd"
                        clipRule="evenodd"
                        d="M11 8C11 9.65686 9.65686 11 8 11C6.34314 11 5 9.65686 5 8C5 6.34314 6.34314 5 8 5C9.65686 5 11 6.34314 11 8Z"
                        fill="currentColor"
                    />
                    <path
                        d="M8 12.25C10.3472 12.25 12.25 10.3472 12.25 8C12.25 5.65279 10.3472 3.75 8 3.75C5.65279 3.75 3.75 5.65279 3.75 8C3.75 10.3472 5.65279 12.25 8 12.25Z"
                        stroke="currentColor"
                        strokeOpacity="0.2"
                        strokeWidth="2.5"
                    />
                </svg>
            );
        case "todo":
            return (
                <svg
                    className="text-content-tertiary"
                    width="16"
                    height="16"
                    viewBox="0 0 16 16"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                >
                    <path
                        fillRule="evenodd"
                        clipRule="evenodd"
                        d="M11 8C11 9.65686 9.65686 11 8 11C6.34314 11 5 9.65686 5 8C5 6.34314 6.34314 5 8 5C9.65686 5 11 6.34314 11 8Z"
                        fill="currentColor"
                    />
                </svg>
            );
    }
};
