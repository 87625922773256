import { cn } from "@/components/podkit/lib/cn";
import { toast } from "@/components/podkit/toasts/use-toast";
import { useSetEnvironmentClassEnabled } from "@/queries/runner-configuration-queries";
import { formatError } from "@/utils/errors";
import type { EnvironmentClass } from "gitpod-next-api/gitpod/v1/runner_configuration_pb";
import { useCallback,  type FC } from "react";

export const EnvironmentClassToggle: FC<{environmentClass: EnvironmentClass}> = ({environmentClass}) => {
    const setEnvironmentClassEnabled = useSetEnvironmentClassEnabled(environmentClass);

    const handleClick = useCallback(async () => {
        try {
            if (environmentClass.enabled) {
                await setEnvironmentClassEnabled.mutateAsync({ enabled: false });
        } else {
                await setEnvironmentClassEnabled.mutateAsync({ enabled: true });
            }
        } catch (error) {
            toast({
                title: `Failed to ${environmentClass.enabled ? "disable" : "enable"} environment class`,
                description: formatError(error),
            });
        }
    }, [setEnvironmentClassEnabled, environmentClass.enabled]);

    return (
            <button
                className={cn(
                    "flex h-6 w-10 items-center gap-2 rounded-xl px-0.5 py-2",
                    "transition-all duration-500 ease-in-out",
                    environmentClass.enabled ? "justify-end" : "justify-start",
                    environmentClass.enabled ? "bg-[#17c165]" : "bg-[#191a1a]/10",
                )}
                onClick={handleClick}
            >
                <div className="flex h-5 w-5 items-center justify-center gap-2 rounded-[10px] bg-white p-2 shadow">
                    <div className="flex h-4 w-4 items-center justify-center gap-2"/>
                </div>
            </button>
    );
};
