import { IconBox } from "@/assets/icons/geist/IconBox";
import { IconBoxLink } from "@/assets/icons/geist/IconBoxLink";
import { IconDot } from "@/assets/icons/geist/IconDot";
import { IconNewEnvironment } from "@/assets/icons/geist/IconNewEnvironment";
import { IconPlay } from "@/assets/icons/geist/IconPlay";
import { IconStop } from "@/assets/icons/geist/IconStop";
import { IconVSCode } from "@/assets/icons/geist/IconVSCode";
import { Collapsable } from "@/components/Collapsable";
import { Button } from "@/components/flexkit/Button";
import { cn } from "@/components/podkit/lib/cn";
import { SkeletonText } from "@/components/podkit/loading/Skeleton";
import { useToast } from "@/components/podkit/toasts/use-toast";
import { Tooltip } from "@/components/Tooltip";
import { useEnvironmentsByProjects, type EnvironmentsGroup } from "@/hooks/use-environments-by-projects";
import { useOpenEditor } from "@/hooks/use-open-editor";
import { TrackLocations } from "@/hooks/use-segment";
import { useStartEnvironment, useStopEnvironment, type PlainEnvironment } from "@/queries/environment-queries";
import { CreateEnvironmentButton } from "@/routes/environments/create/CreateEnvironmentButton";
import { getDetailsURL } from "@/routes/environments/details-url";
import {
    canOpen,
    effectiveState,
    environmentHasReachedStablePhase,
    showStart,
    showStop,
} from "@/routes/environments/phase";
import { formatError } from "@/utils/errors";
import { gitStatusChangesCount, gitStatusLabel } from "@/utils/git-status";
import type { PlainMessage } from "@bufbuild/protobuf";
import { EnvironmentPhase, type EnvironmentGitStatus } from "gitpod-next-api/gitpod/v1/environment_pb";
import { useCallback, useEffect, useMemo, useState, type FC } from "react";
import { Link, useParams } from "react-router-dom";

export const SidebarEnvironmentList: FC = () => {
    const { data: envsByProject, isLoading } = useEnvironmentsByProjects();

    if (isLoading) {
        return null;
    }
    return (
        <div
            className="contents"
            data-testid="environments-list"
            data-track-location={TrackLocations.SidebarEnvironmentList}
        >
            {envsByProject.map((group) => (
                <Group group={group} key={`group-${group.projectId || group.repoFullName}`} />
            ))}
        </div>
    );
};

const Group: FC<{
    group: EnvironmentsGroup;
}> = ({ group }) => {
    const { environmentId } = useParams();
    const shouldOpen = useMemo(() => {
        if (environmentId) {
            if (group.environments.some((env) => env.id === environmentId)) {
                return true;
            }
        }
        return group.environments.some(
            (env) =>
                env.status?.phase && [EnvironmentPhase.RUNNING, EnvironmentPhase.STARTING].includes(env.status.phase),
        );
    }, [environmentId, group.environments]);
    const [openState, setOpenState] = useState<"open" | "closed">(shouldOpen ? "open" : "closed");

    /**
     * When the group's `shouldOpen` computed state changes to true, we want to open the group.
     */
    useEffect(() => {
        if (shouldOpen) {
            setOpenState("open");
        }
    }, [shouldOpen]);

    const toggleOpen = useCallback(() => {
        setOpenState((current) => (current === "open" ? "closed" : "open"));
    }, []);

    return (
        <div className="flex flex-col">
            <div className="group flex h-9 w-full select-none flex-row content-center items-center justify-between rounded-lg hover:bg-surface-03">
                <div className="flex-grow overflow-hidden">
                    <button
                        onClick={toggleOpen}
                        className="flex w-full select-none flex-row content-center items-center"
                    >
                        <div className="flex pl-2">
                            {group.project ? <IconBox state={openState} /> : <IconBoxLink state={openState} />}
                        </div>
                        <div className="truncate pl-2 text-base" translate="no">
                            {group.repoFullName ? (
                                group.repoFullName
                            ) : group.projectMissing ? (
                                "Deleted project"
                            ) : (
                                <SkeletonText size="base" className="w-32" ready={!!group.project}>
                                    {group.project?.metadata?.name || "Untitled project"}
                                </SkeletonText>
                            )}
                        </div>
                    </button>
                </div>
                {group.project && (
                    <div className="mr-2 flex items-center opacity-0 group-hover:opacity-100">
                        <CreateEnvironmentButton
                            project={group.project}
                            LeadingIcon={IconNewEnvironment}
                            variant={"ghost"}
                            size={"tiny"}
                            className="border-0 text-content-secondary"
                            onCreateSuccess={() => setOpenState("open")}
                        />
                    </div>
                )}
            </div>
            <Collapsable collapsed={openState === "closed"}>
                <div className="mt-1 flex flex-col gap-1">
                    {group.environments.map((env) => (
                        <Env key={env.id} env={env} active={location.pathname.includes("/" + env.id)} />
                    ))}
                </div>
            </Collapsable>
        </div>
    );
};

const Env: FC<{ env: PlainEnvironment; active?: boolean }> = (p) => {
    return (
        <Link
            className={cn(
                "group h-9 w-full select-none rounded-lg px-2 py-[6px]",
                "flex items-center justify-between",
                p.active ? "bg-surface-secondary" : "hover:bg-surface-03",
            )}
            to={getDetailsURL(p.env)}
        >
            <div className="flex min-w-0 items-center gap-2">
                <EnvironmentStatusIndicator env={p.env} />
                <EnvironmentName env={p.env} />
            </div>

            <div className="flex items-center gap-[2px]">
                <div className="hidden group-hover:block">
                    <EnvironmentSidebarActions env={p.env} />
                </div>
                <EnvironmentChanges git={p.env.status?.content?.git} />
            </div>
        </Link>
    );
};

const EnvironmentName: FC<{ env: PlainEnvironment }> = ({ env }) => {
    const branch = useMemo(() => {
        if (!env.status?.content?.git?.branch) {
            return "main";
        }
        return env.status?.content?.git?.branch;
    }, [env]);
    let label = branch;
    const failureMessages = env.status?.failureMessage || [];
    const hasFailures = failureMessages.length > 0;
    if (!label && hasFailures) {
        label = "no branch";
    }

    return (
        <SkeletonText size="base" className="w-32" ready={!!label}>
            <span className="truncate text-base" translate="no">
                {label}
            </span>
        </SkeletonText>
    );
};

const EnvironmentChanges: FC<{ git?: PlainMessage<EnvironmentGitStatus> }> = ({ git }) => {
    const changes = gitStatusChangesCount(git) ?? 0;

    if (changes === 0) {
        return null;
    }

    const tooltip = gitStatusLabel(git) ?? "";
    const label = changes > 99 ? "+99" : `${changes}`;
    return (
        <Tooltip content={tooltip}>
            <span className="rounded-[10px] bg-surface-03 px-[6px] py-[2px] text-sm font-bold">{label}</span>
        </Tooltip>
    );
};

const EnvironmentSidebarActions: FC<{ env: PlainEnvironment }> = ({ env }) => {
    const handleOpen = useOpenEditor(env);
    const startEnvironment = useStartEnvironment();
    const stopEnvironment = useStopEnvironment();
    const { toast } = useToast();

    const handleStart = useCallback(
        async (e: React.SyntheticEvent) => {
            e.stopPropagation();
            e.preventDefault();
            try {
                await startEnvironment.mutateAsync(env.id);
            } catch (error) {
                toast({
                    title: "Failed to start environment",
                    description: formatError(error),
                });
            }
        },
        [startEnvironment, env.id, toast],
    );

    const handleStop = useCallback(
        async (e: React.SyntheticEvent) => {
            e.stopPropagation();
            e.preventDefault();
            try {
                await stopEnvironment.mutateAsync(env.id);
            } catch (error) {
                toast({
                    title: "Failed to stop environment",
                    description: formatError(error),
                });
            }
        },
        [stopEnvironment, toast, env.id],
    );

    return (
        <div className="flex items-center gap-[2px]">
            <Tooltip content="Open in VS Code">
                <Button
                    disabled={!canOpen(env)}
                    onClick={(e) => {
                        e.preventDefault();
                        e.stopPropagation();
                        handleOpen();
                    }}
                    LeadingIcon={IconVSCode}
                    size="tiny"
                    variant="ghost"
                    data-track-label="Open VS Code"
                    className={cn("border-0 text-content-secondary", !canOpen(env) && "hidden")}
                />
            </Tooltip>
            {environmentHasReachedStablePhase(env) && (
                <>
                    {showStart(env) && (
                        <Tooltip content="Start Environment">
                            <Button
                                size="tiny"
                                variant="ghost"
                                LeadingIcon={IconPlay}
                                onClick={handleStart}
                                loading={startEnvironment.isPending}
                                data-track-label="Start Environment"
                                className="border-0 text-content-secondary"
                            />
                        </Tooltip>
                    )}
                    {showStop(env) && (
                        <Tooltip content="Stop Environment">
                            <Button
                                size="tiny"
                                variant="ghost"
                                LeadingIcon={IconStop}
                                onClick={handleStop}
                                loading={stopEnvironment.isPending}
                                data-track-label="Stop Environment"
                                className="border-0 text-content-secondary"
                            />
                        </Tooltip>
                    )}
                </>
            )}
        </div>
    );
};

const EnvironmentStatusIndicator: FC<{ env: PlainEnvironment }> = ({ env }) => {
    const state = useMemo(() => effectiveState(env), [env]);
    const { color } = useMemo(() => {
        let color = "text-content-tertiary size-2 m-2";

        switch (state.state) {
            case EnvironmentPhase.RUNNING:
                color = "text-content-green";
                break;
            case EnvironmentPhase.UPDATING:
                color = "text-content-tertiary";
                break;
            case EnvironmentPhase.STARTING:
            case EnvironmentPhase.CREATING:
            case EnvironmentPhase.STOPPING:
                color = "text-content-orange";
                break;
            case EnvironmentPhase.STOPPED:
                if (state.failures) {
                    color = "text-content-red";
                } else {
                    color = "text-content-tertiary size-2 m-2";
                }
                break;
            case EnvironmentPhase.DELETING:
                color = "text-content-gray-600 opacity-50";
                break;
        }
        return {
            color,
            ...state,
        };
    }, [state]);

    return (
        <div className="min-w-6">
            <IconDot size="lg" className={color} />
        </div>
    );
};
